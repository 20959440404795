import Footer from 'components/layout/footer'
// import { Container } from 'react-bootstrap'

const AuthFooter = () => {
  return (
    <Footer />
  )
  // return (
  //   <Container className='p-3 mt-5 pt-5 small text-muted'>
  //     &copy; {new Date().getFullYear()} Imaginate. All rights reserved.
  //   </Container>
  // )
}

export default AuthFooter
