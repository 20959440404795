export const LOGIN = 'engage/auth/LOGIN'
export const SET_EMAIL = 'engage/auth/SET_EMAIL'
export const LOGOUT = 'engage/auth/LOGOUT'

const initialState = () => ({
  token: null,
  email: null
})

const reducer = (state = initialState(), { type, data }) => {
  switch (type) {
    case LOGIN:
      return {
        ...state,
        token: data.token
      }
    case SET_EMAIL:
      return {
        ...state,
        email: data.email
      }
    case LOGOUT:
      return initialState()
    default:
      return state
  }
}

export default reducer

export const authLogin = ({ token }) => {
  return {
    type: LOGIN,
    data: { token },
  }
}

export const authSetEmail = (email) => {
  return {
    type: SET_EMAIL,
    data: { email }
  }
}

export const authLogout = () => {
  return {
    type: LOGOUT,
  }
}
