import { useState, useRef, useEffect, useCallback } from 'react'
import { Routes, Route, Link, useParams, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Container, Nav, Alert, Spinner } from 'react-bootstrap'
import { loadEvent, clearEvent, updateEvent } from 'store/event'
// import { io } from 'socket.io-client'
import useAPI from 'hooks/use-api'
// import LiveRemote from './live-remote'
// import { Monitor } from 'react-feather'

import Dashboard from './dashboard'
import Guests from './guests'
import Actions from './actions'
import Feature from './feature'
import Reports from './reports'
// import useSocket from 'hooks/use-socket'

const EventScreen = () => {

    const { eventId } = useParams()
    const event = useSelector(state => state.event)
    const features = event?.features?.map(f => f).sort((a, b) => a.sort > b.sort ? 1 : a.sort < b.sort ? -1 : 0) || []

    const startTime = new Date(event?.startsAt).getTime()
    const endTime = new Date(event?.endsAt).getTime()
    const rightNow = Date.now()
    const hasStarted = rightNow > startTime
    const hasEnded = rightNow > endTime

    const [mode, setMode] = useState(hasStarted && !hasEnded ? 'live' : null)
    // const [showRemote, setShowRemote] = useState()
    const [error, setError] = useState()
    const dispatch = useDispatch()
    const api = useAPI('/admin')
    // const socket = useSocket()
    const navigate = useNavigate()
    const interval = useRef()
    const lastEventId = useRef()

    const toggleLiveMode = () => setMode(mode => mode === 'live' ? null : 'live')
    // const handleToggleRemote = () => setShowRemote(showRemote => !showRemote)

    const pollActions = useCallback(() => {
        api.get('/event/' + eventId + '/action')
        .then(actions => {
            dispatch(updateEvent({
                actions
            }))
        })
        .catch(console.error)
    }, [api, dispatch, eventId])

    useEffect(() => {
        if (mode === 'live') {
            interval.current = setInterval(pollActions, 2 * 1000)
        } else if (interval.current) {
            clearInterval(interval.current)
        }
        return () => clearInterval(interval.current)
    }, [mode, pollActions])

    useEffect(() => {
        if (eventId === lastEventId.current) return;
        lastEventId.current = eventId
        api.get('/event/' + eventId)
        .then(async event => {
            dispatch(loadEvent(event))
            try {
                const guests = await api.get('/event/' + eventId + '/guest')
                const categories = await api.get('/event/' + eventId + '/category')
                const entities = await api.get('/event/' + eventId + '/entity')
                const features = await api.get('/event/' + eventId + '/feature')
                const actions = await api.get('/event/' + eventId + '/action')
                dispatch(updateEvent({
                    ...event,
                    guests,
                    categories,
                    entities,
                    features,
                    actions
                }))
                const now = Date.now()
                const start = new Date(event.startsAt).getTime()
                const end = new Date(event.endsAt).getTime()
                if (end > now) {
                    setMode('read-only')
                } else if (start > now) {
                    setMode('live')
                }
                // socket.current = io(process.env.REACT_APP_API_URL, {
                //     path: '/io/',
                //     auth: {
                //         token: event.token
                //     }
                // })
                // socket.current.on('connect', () => {
                //     console.log('Socket connected', socket.current.id)
                // })
                // socket.current.on('disconnect', () => {
                //     console.log('Socket disconnected')
                // })
            } catch (error) {
                setError(error)
            }
        })
        .catch(setError)
        // return () => {
        //     if (socket.current) socket.current.disconnect()
        // }
    }, [eventId, api, dispatch])

    if (!event) return null

    return (
        <Container className='p-3'>
            <div className='d-flex'>
                <div className='flex-grow-1'>
                    <Link to='/' onClick={() => {
                        dispatch(clearEvent())
                        navigate('/')
                    }}>← Events</Link>
                </div>
                {event && (
                    <>
                        <div>
                            <div className='form-check form-switch form-check-lg'>
                                <input className='form-check-input' type='checkbox' id='eventLiveSwitch' onChange={toggleLiveMode} checked={mode === 'live'} />
                                <label className='form-check-label' htmlFor='eventLiveSwitch'>Live</label>
                            </div>
                        </div>
                        {/* {mode === 'live' && <div className='ps-2'>
                            <Button variant='link' className='p-0' onClick={handleToggleRemote} disabled={mode !== 'live'}><Monitor style={{ height: '1.375rem', verticalAlign: 'top' }} /></Button>
                        </div>} */}
                    </>
                )}
            </div>
            {error ? (
                <Alert variant='warning' className='mt-3'>{error.message}</Alert>
            ) : event ? (
                <>
                    <h1 className='mt-1 mb-2' style={{ lineHeight: 1 }}>{event.name}</h1>
                    <div>
                        <Nav variant='pills' activeKey={window.location.pathname} className='mb-3'>
                            <Nav.Item>
                                <Nav.Link as={Link} to={'/event/' + eventId} eventKey={'/event/' + eventId}>Event</Nav.Link>
                            </Nav.Item>
                            {features?.map((feature, index) => (
                                <Nav.Item key={index}>
                                    <Nav.Link as={Link} to={'/event/' + eventId + '/feature/' + feature.id} eventKey={'/event/' + eventId + '/feature/' + feature.id}>{feature.name}</Nav.Link>
                                </Nav.Item>
                            ))}
                            {/* {hasStarted && ( */}
                                <>
                                    <Nav.Item>
                                        <Nav.Link as={Link} to={'/event/' + eventId + '/guest'} eventKey={'/event/' + eventId + '/guest'}>Guests</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link as={Link} to={'/event/' + eventId + '/action'} eventKey={'/event/' + eventId + '/action'}>Actions</Nav.Link>
                                    </Nav.Item>
                                </>
                            {/* )} */}
                            {hasEnded && (
                                <Nav.Item>
                                    <Nav.Link as={Link} to={'/event/' + eventId + '/report'} eventKey={'/event/' + eventId + '/report'}>Reports</Nav.Link>
                                </Nav.Item>
                            )}
                        </Nav>
                    </div>
                    <div className='mx-n3 mx-sm-0'>
                    <Routes>
                        <Route exact path='/' element={<Dashboard liveMode={mode === 'live'} readOnly={hasEnded} />} />
                        <Route path='/guest' element={<Guests liveMode={mode === 'live'} readOnly={hasEnded} />} />
                        <Route path='/action' element={<Actions liveMode={mode === 'live'} readOnly={hasEnded} />} />
                        {features?.map((feature, index) => (
                            <Route key={index} path={'/feature/' + feature.id} element={(
                                feature.type === 'segment' ? (
                                    <Feature featureId={feature.id} liveMode={mode === 'live'} readOnly={hasEnded} />
                                ) : feature.type === 'speaker' ? (
                                    <Feature featureId={feature.id} liveMode={mode === 'live'} readOnly={hasEnded} />
                                ) : feature.type === 'sponsor' ? (
                                    <Feature featureId={feature.id} liveMode={mode === 'live'} readOnly={hasEnded} />
                                ) : feature.type === 'startup' ? (
                                    <Feature featureId={feature.id} liveMode={mode === 'live'} readOnly={hasEnded} />
                                ) : null
                            )} />
                        ))}
                        {hasEnded && (
                            <Route path='/report' element={<Reports liveMode={mode === 'live'} readOnly={mode === 'read-only'} />} />
                        )}
                    </Routes>
                    </div>
                    {/* <Modal centered show={showRemote} onHide={handleToggleRemote}>
                        <Modal.Header closeButton>LiveView&trade;</Modal.Header>
                        <Modal.Body>
                            <LiveRemote event={event} socket={socket} />
                        </Modal.Body>
                    </Modal> */}
                </>
            ) : <div><Spinner style={{ color: '#ccc' }} animation='border' /></div>}
        </Container>
    )
}

export default EventScreen