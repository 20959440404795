import { useSelector } from 'react-redux'
import { Card, Row, Col } from 'react-bootstrap'
import SettingsSection from './settings'
import FeaturesSection from './features'
import ResetTestDataButton from './reset'

const DashboardScreen = ({ liveMode, readOnly }) => {

    const event = useSelector(state => state.event)

    return (
        <>
            <Card className='mb-3'>
                <Card.Body>
                    <Row xs={1} lg={2}>
                        <Col>
                            <SettingsSection event={event} liveMode={liveMode} readOnly={readOnly} />
                        </Col>
                        <Col>
                            <FeaturesSection event={event} liveMode={liveMode} readOnly={readOnly} />
                            {!readOnly && <ResetTestDataButton event={event} />}
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </>
    )

}

export default DashboardScreen