import Footer from './footer'
import Header from './header'
import style from './style.module.css'

const Layout = ({ event, children }) => {

    return (
        <>
            <div className={style.layout}>
                <Header event={event} />
                <div className={style.content}>
                    {children}
                </div>
                <Footer />
            </div>
        </>
    )

}

export default Layout