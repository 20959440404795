import { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import { Row, Col, Button, Form, ListGroup } from 'react-bootstrap'
import FormGroup from 'components/form-group'
import { XCircle, PlusCircle } from 'react-feather'
import Field from 'components/formik/field'
import Check from 'components/formik/check'
import ImageInput from 'components/image-input'
import * as yup from 'yup'

const schema = yup.object().shape({
    name: yup.string().required().label('Name'),
    logoUrl: yup.string().nullable().required().label('Logo'),
    description: yup.string().label('Description'),
    websiteUrl: yup.string().url().label('Website'),
    hostedBy: yup.string().label('Hosted by'),
    venueName: yup.string().label('Venue name'),
    startDate: yup.string().required().label('Start date'),
    startTime: yup.string().required().label('Start time'),
    endDate: yup.string().required().label('End date'),
    endTime: yup.string().required().label('End time'),
    primaryColor: yup.string().required().label('Primary color'),
    secondaryColor: yup.string().required().label('Secondary color'),
    fields: yup.array().of(yup.object().shape({
        type: yup.string().oneOf(['text', 'tel', 'url', 'number', 'select']).required().label('Type'),
        label: yup.string().required().label('Label'),
        options: yup.array().of(yup.string()).label('Options'), // .when('type', (type, schema) => type === 'select' ? schema.required() : schema),
        required: yup.boolean().label('Required')
    })).max(2).label('Fields')
})

const defaults = values => {
    let startDate, startTime, endDate, endTime
    if (values?.startsAt) {
        const start = new Date(values.startsAt)
        startDate = start.getFullYear() + '-' + String(start.getMonth() + 1).padStart(2, '0') + '-' + String(start.getDate()).padStart(2, '0')
        startTime = String(start.getHours()).padStart(2, '0') + ':' + String(start.getMinutes()).padStart(2, '0')
    }
    if (values?.endsAt) {
        const end = new Date(values.endsAt)
        endDate = end.getFullYear() + '-' + String(end.getMonth() + 1).padStart(2, '0') + '-' + String(end.getDate()).padStart(2, '0')
        endTime = String(end.getHours()).padStart(2, '0') + ':' + String(end.getMinutes()).padStart(2, '0')
    }
    return {
        name: values?.name || '',
        logoUrl: values?.logoUrl || '',
        description: values?.description || '',
        websiteUrl: values?.websiteUrl || '',
        hostedBy: values?.hostedBy || '',
        venueName: values?.venueName || '',
        startsAt: values?.startsAt || '',
        startDate: startDate || '',
        startTime: startTime || '',
        endsAt: values?.endsAt || '',
        endDate: endDate || '',
        endTime: endTime || '',
        primaryColor: values?.primaryColor || '',
        secondaryColor: values?.secondaryColor || '',
        fields: values?.fields || []
    }
}

const EventForm = ({ values, onSubmit, onReset }) => {

    const handleSubmit = values => {
        const startsAt = new Date(values.startDate + ' ' + values.startTime)
        const endsAt = new Date(values.endDate + ' ' + values.endTime)
        onSubmit({
            ...values,
            startsAt,
            endsAt
        })
    }

    const initialValues = useMemo(() => {
        return defaults(values)
    }, [values])

    return (
        <Formik initialValues={initialValues} onSubmit={handleSubmit} onReset={onReset} validationSchema={schema} validateOnChange={false} validateOnBlur={false}>
            {({ values, setFieldValue, errors, handleSubmit, handleReset, submitting }) => {
                return (
                    <form onSubmit={handleSubmit} onReset={handleReset} noValidate autoComplete='off'>
                        {/* <Row sm={2}>
                            <Col> */}
                                <Field label='Name' type='text' name='name' />
                            {/* </Col>
                        </Row> */}
                        {/* <Field label='Logo' type='file' name='logo' /> */}
                        <Field label='Description' type='textarea' name='description' rows={4} style={{ resize: 'none' }} />
                        <Row xs={1} md={2}>
                            <Col>
                                <Field label='Hosted by' type='text' name='hostedBy' />
                            </Col>
                            <Col>
                                <Field label='Website' type='url' name='websiteUrl' placeholder='https://' />
                            </Col>
                        </Row>
                        {/* <Field label='Venue' type='text' name='venueName' /> */}
                        {/* <Field label='Virtual URL' type='url' name='virtualUrl' /> */}
                        <Row xs={1} sm={2}>
                            <Col>
                                <Form.Label>Starts</Form.Label>
                                <Row xs={2} sm={1} lg={2} className='g-2'>
                                    <Col>
                                        <Field type='date' name='startDate' onChange={e => {
                                            setFieldValue('startDate', e.target.value)
                                            if (e.target.value && (
                                                !values.endDate ||
                                                new Date(values.endDate).getTime() < new Date(e.target.value).getTime()
                                            )) {
                                                setFieldValue('endDate', e.target.value)
                                            }
                                        }} />
                                    </Col>
                                    <Col>
                                        <Field type='time' name='startTime' />
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Form.Label>Ends</Form.Label>
                                <Row xs={2} sm={1} lg={2} className='g-2'>
                                    <Col>
                                        <Field type='date' name='endDate' />
                                    </Col>
                                    <Col>
                                        <Field type='time' name='endTime' />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row xs={1} sm={2}>
                            <Col>
                                <FormGroup>
                                    <label className='form-label d-block'>Logo</label>
                                    <ImageInput value={{ url: values.logoUrl }} onChange={image => setFieldValue('logoUrl', image?.url || null)} />
                                    {!!errors.logoUrl && (
                                        <Form.Control.Feedback type='invalid' className='d-block'>
                                            {errors.logoUrl}
                                        </Form.Control.Feedback>
                                    )}
                                </FormGroup>
                                {/* <Field label='Logo' type='url' name='logoUrl' placeholder='https://' /> */}
                            </Col>
                            <Col>
                                <Form.Label>Colors</Form.Label>
                                <Row xs={2} sm={1} lg={2} className='g-2'>
                                    <Col>
                                        <Field type='color' name='primaryColor' inputStyle={{ height: '2.375rem' }} />
                                    </Col>
                                    <Col>
                                        <Field type='color' name='secondaryColor' inputStyle={{ height: '2.375rem' }} />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <div>
                            <div className='d-flex align-items-center'>
                                <Form.Label>
                                    Signup fields
                                </Form.Label>
                                {values.fields.length < 2 && <Button size='sm' variant='link' className='p-0 m-0 mt-n1 ms-1' style={{ verticalAlign: 'top' }} onClick={() => setFieldValue('fields', [...values.fields, { type: 'text', label: '' }])}>
                                    <PlusCircle style={{ height: '1.25rem', width: '1.25rem' }} />
                                </Button>}
                            </div>
                            <ListGroup>
                                {values.fields.map((field, index) => (
                                    <ListGroup.Item key={index} className='position-relative'>
                                        <Row className='g-2'>
                                            <Col xs={6} sm={4}>
                                                <Field type='select' name={`fields[${index}].type`} label='Type' className='mb-2' inputClassName='form-select-sm'>
                                                    <option value='text'>Text</option>
                                                    <option value='tel'>Phone</option>
                                                    <option value='url'>URL</option>
                                                    <option value='number'>Number</option>
                                                    <option value='select'>Select</option>
                                                </Field>
                                            </Col>
                                            <Col xs={6} sm={4}>
                                                <Field type='text' name={`fields[${index}].label`} label='Label' className='mb-2' size='sm' />
                                            </Col>
                                            <Col xs={6} sm={4}>
                                                {field.type === 'select' && (
                                                    <FormGroup className='mb-2'>
                                                        <Form.Label>Options</Form.Label>
                                                        <Form.Control
                                                            type='text'
                                                            size='sm'
                                                            placeholder='Separate with commas'
                                                            defaultValue={field.options ? field.options.join(', ') : ''}
                                                            onChange={e => setFieldValue(`fields[${index}].options`, e.target.value ? e.target.value.split(',').map(option => option.trim()).filter(option => !!option) : [])}
                                                        />
                                                    </FormGroup>
                                                )}
                                            </Col>
                                        </Row>
                                        <Button size='sm' className='float-end p-0 text-danger' variant='link' onClick={() => {
                                            setFieldValue('fields', values.fields.filter((f, i) => i !== index))
                                        }} style={{ position: 'absolute', top: '0.125rem', right: '0.25rem' }}>
                                            <XCircle style={{ height: '1rem', width: '1rem' }} />
                                        </Button>
                                        <Check type='checkbox' label='Required' name={`fields[${index}].required`} className='small' />
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>
                        </div>
                        <div className='mt-4'>
                            <Button variant='primary' type='submit' disabled={submitting}>Save</Button>
                            <Button variant='link' type='reset'>Cancel</Button>
                        </div>
                    </form>
                )

            }}
        </Formik>
    )

}

EventForm.propTypes = {
    values: PropTypes.object,
    onSubmit: PropTypes.func.isRequired,
    onReset: PropTypes.func.isRequired
}

export default EventForm



// // <div className='mb-3'>
// <Form.Label>
//     Features
//     {values.features.length < 4 && <Button size='sm' variant='link' className='p-0 m-0 ms-1' style={{ verticalAlign: 'top' }} onClick={() => setFieldValue('features', [...values.features, { type: '', name: '', icon: '' }])}>
//         <PlusCircle style={{ height: '1.25rem', width: '1.25rem' }} />
//     </Button>}
// </Form.Label>
// <ListGroup>
//     {values.features.map((feature, index) => {
//         const disabled = values.features.filter((f, i) => f.type !== feature.type && i !== index).map(f => f.type)
//         return (
//             <ListGroup.Item key={index} className='position-relative'>
//                 <Row className='align-items-center'>
//                     <Col xs={6} sm={4}>
//                         <Field type='select' name={`features[${index}].type`} label='Type' className='mb-2' size='sm'>
//                             <option />
//                             <option value='segment' disabled={disabled.indexOf('segment') > -1}>Segments</option>
//                             <option value='speaker' disabled={disabled.indexOf('speaker') > -1}>Speakers</option>
//                             <option value='sponsor' disabled={disabled.indexOf('sponsor') > -1}>Sponsors</option>
//                             <option value='startup' disabled={disabled.indexOf('startup') > -1}>Startups</option>
//                         </Field>
//                     </Col>
//                     <Col xs={6} sm={4}>
//                         <Field type='text' name={`features[${index}].name`} label='Name' className='mb-2' size='sm' />
//                     </Col>
//                     {/* <Col xs={6} sm={4}>
//                         <Field type='select' name={`features[${index}].icon`} label='Icon' className='mb-2' size='sm'>
//                             {featureIcons.map((icon, index) => (
//                                 <option key={index} value={icon}>{icon}</option>
//                             ))}
//                         </Field>
//                     </Col> */}
//                 </Row>
//                 <Button size='sm' className='p-0 text-danger' variant='link' onClick={() => {
//                     setFieldValue('features', values.features.filter((f, i) => i !== index))
//                 }} style={{ position: 'absolute', top: '0.125rem', right: '0.25rem' }}>
//                     <XCircle style={{ height: '1rem', width: '1rem' }} />
//                 </Button>
//             </ListGroup.Item>
//         )
//     })}
// </ListGroup>
// // </div>