import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button, Modal, ListGroup, Form, InputGroup, Badge } from 'react-bootstrap'
import { ReactSortable } from 'react-sortablejs'
import { AlignJustify, X } from 'react-feather'
import { updateEvent } from 'store/event'
import useAPI from 'hooks/use-api'
import ConfirmDelete from 'components/confirm-delete'
import { EditText } from 'react-edit-text'
import 'react-edit-text/dist/index.css'

const Categories = ({ featureId }) => {

    const event = useSelector(state => state.event)
    const eventId = event?.id
    const categories = event?.categories?.filter(c => c.featureId === featureId) // ?.sort((a, b) => a.sort > b.sort ? 1 : a.sort < b.sort ? -1 : 0) || []
    const [newCategory, setNewCategory] = useState('')
    const [showModal, setShowModal] = useState()
    const [error, setError] = useState()
    const dispatch = useDispatch()
    const api = useAPI('/admin')

    const toggleShowModal = () => setShowModal(showModal => !showModal)

    const handleNew = category => {
        if (!category?.name || categories.findIndex(c => c.name.toLowerCase() === category.name.toLowerCase()) > -1) return false
        api.post('/event/' + eventId + '/category', category)
        .then(category => {
            dispatch(updateEvent({
                categories: event.categories ? [
                    ...event.categories,
                    category
                ] : [category]
            }))
            setError()
            setNewCategory('')
        })
        .catch(setError)
    }

    const handleEdit = category => {
        api.put('/event/' + eventId + '/category/' + category.id, category)
        .then(category => {
            dispatch(updateEvent({
                categories: event.categories.map(c => c.id === category.id ? category : c)
            }))
            setError()
        })
        .catch(setError)
    }

    const handleDelete = category => {
        api.delete('/event/' + eventId + '/category/' + category.id)
        .then(() => {
            dispatch(updateEvent({
                categories: event.categories.filter(c => c.id !== category.id)
            }))
            setError()
        })
        .catch(setError)
    }

    const handleReorder = sorted => {
        const ids = sorted.map(c => c.id)
        const old = categories.map(c => c.id)
        let changed = false
        ids.forEach((id, index) => {
            if (changed) return
            if (index !== old.indexOf(id)) changed = true
        })
        if (!changed) return false
        api.put('/event/' + eventId + '/category', { categories: sorted.map((c, i) => ({ id: c.id, sort: i })) })
        .then(() => {
            dispatch(updateEvent({
                categories: categories.map(c => ids.indexOf(c.id) > -1 ? { ...c, sort: ids.indexOf(c.id)} : c).sort((a, b) => a.sort > b.sort ? 1 : a.sort < b.sort ? -1 : 0)
            }))
            setError()
        })
        .catch(setError)
    }

    return (
        <>
            
            <Button variant='link' className='mt-3' onClick={toggleShowModal}>
                Categories
                {categories?.length > 0 && <Badge variant='primary' className='ms-1'>{categories?.length}</Badge>}
            </Button>
            <Modal centered show={showModal} onHide={toggleShowModal} size='md'>
                <Modal.Header>
                    Categories
                </Modal.Header>
                <Modal.Body>
                    <ReactSortable tag={ListGroup} variant='flush' className='mb-2' list={categories || []} setList={handleReorder}>
                        {categories?.map(category => {
                            const count = event?.entities.filter(e => e.categoryId === category.id).length
                            return (
                                <ListGroup.Item key={category.id} className='d-flex p-2 align-items-center'>
                                    <AlignJustify style={{ color: '#ccc', height: '1.5rem', width: '1.5rem', cursor: 'grab' }} />
                                    <div className='flex-grow-1 mx-2'>
                                        <EditText defaultValue={category.name} className='my-n2' onSave={e => handleEdit({ ...category, name: e.value })} />
                                    </div>
                                    <div className='mx-2'>
                                        {count}
                                    </div>
                                    {/* <div> */}
                                        <ConfirmDelete disabled={count} onClick={() => handleDelete(category)} variant='link' className={'p-0 ' + (count ? 'text-muted' : 'text-danger')} style={{ lineHeight: 0 }}>
                                            <X style={{ height: '1.25rem', width: '1.25rem' }} />
                                        </ConfirmDelete>
                                    {/* </div> */}
                                </ListGroup.Item>
                            )
                        })}
                    </ReactSortable>
                    {(!categories || categories.length < 12) && (
                        <Form noValidate autoComplete='off' onSubmit={e => { e.preventDefault(); e.stopPropagation(); handleNew({ featureId, name: newCategory }) }}>
                            <InputGroup>
                                <Form.Control type='text' placeholder='Category name...' value={newCategory} onChange={e => setNewCategory(e.target.value)} />
                                <Button type='submit' style={{ borderTopRightRadius: '0.25rem', borderBottomRightRadius: '0.25rem' }}>Add</Button>
                            </InputGroup>
                        </Form>
                    )}
                    <Button onClick={toggleShowModal} className='mt-3'>Done</Button>
                </Modal.Body>
            </Modal>
        </>
    )

}

export default Categories