import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button, Form } from 'react-bootstrap'
import { AlignJustify } from 'react-feather'
import { updateEvent } from 'store/event'
import useAPI from 'hooks/use-api'

const SegmentListItem = ({ entity, liveMode, readOnly, onClick }) => {

    const event = useSelector(state => state.event)
    const [error, setError] = useState()
    const dispatch = useDispatch()
    const api = useAPI('/admin')

    const handleClick = () => {
        if (!onClick || typeof onClick !== 'function') return false
        onClick(entity)
    }

    const handleCheck = e => {
        if (!event) return false
        const checked = e.target.checked
        api.put('/event/'+ event.id + '/entity/' + entity.id, {
            badge: checked ? 'current' : null
        })
        .then(async () => {
            if (checked) {
                for (let i = 0; i < event.entities.length; i++) {
                    const ent = event.entities[i]
                    if (
                        ent.featureId === entity.featureId &&
                        ent.id !== entity.Id &&
                        ent.badge
                    ) {
                        await api.put('/event/'+ event.id + '/entity/' + ent.id, {
                            badge: null
                        })
                    }
                }
            }
            dispatch(updateEvent({
                ...event,
                entities: event.entities.map(e => e.id === entity.id ? ({ ...e, badge: checked ? 'current' : null }) : e.featureId === entity.featureId ? ({ ...e, badge: null }) : e)
            }))
        })
        .catch(setError)
    }

    return (
        <div className='d-flex p-2 align-items-center'>
            {!liveMode && !readOnly && <AlignJustify style={{ color: '#ccc', height: '1.5rem', width: '1.5rem', cursor: 'grab' }} />}
            {!liveMode && !readOnly ? (
                <Button onClick={handleClick} variant='link' className='m-0 p-0 flex-grow-1 mx-2 text-dark text-start border-0'>
                    {entity.name}
                    {entity.detail && <div className='d-inline small text-muted ms-2'>{entity.detail}</div>}
                </Button>
            ) : (
                <div className='flex-grow-1'>
                    {entity.name}
                    {entity.detail && <div className='d-inline small text-muted ms-2'>{entity.detail}</div>}
                </div>
            )}
            {liveMode && !readOnly && <Form.Check type='checkbox' id={'segment-' + entity.id} label='Current' className='m-0' onChange={handleCheck} checked={!!entity.badge} />}
        </div>
    )

}

export default SegmentListItem