import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Card, Button, Modal, Alert, Spinner } from 'react-bootstrap'
import { updateEvent } from 'store/event'
import useAPI from 'hooks/use-api'
import EntityForm from 'components/forms/entity'
import Categories from './categories'
import EntityList from './entities'

const FeatureScreen = ({ featureId, liveMode, readOnly }) => {

    const event = useSelector(state => state.event)
    const eventId = event?.id
    const feature = event?.features?.find(f => f.id === featureId)
    const entities = event?.entities?.filter(e => e.featureId === featureId)
    const categories = event?.categories?.filter(c => c.featureId === featureId)
    const [modal, setModal] = useState()
    const [error, setError] = useState()
    const [editing, setEditing] = useState()
    const dispatch = useDispatch()
    const api = useAPI('/admin')

    const handleShowModal = () => setModal('new')
    const handleHideModal = () => setModal()

    const handleNew = values => {
        api.post('/event/' + eventId + '/entity', {
            ...values,
            featureId,
            sort: entities?.length || 0
        })
        .then(entity => {
            dispatch(updateEvent({
                entities: [...event.entities, entity]
            }))
            setModal()
        })
        .catch(setError)
    }

    const handleEdit = values => {
        if (!editing) return
        api.put('/event/' + eventId + '/entity/' + editing.id, {
            ...editing,
            ...values,
            featureId
        })
        .then(entity => {
            dispatch(updateEvent({
                entities: event.entities.map(e => e.id === entity.id ? entity : e)
            }))
            setEditing()
        })
        .catch(setError)
    }

    const handleCancelEdit = () => setEditing()

    const handleDelete = () => {
        if (!editing) return
        api.delete('/event/' + eventId + '/entity/' + editing.id)
        .then(() => {
            dispatch(updateEvent({
                entities: event.entities.filter(e => e.id !== editing.id)
            }))
            setEditing()
        })
        .catch(setError)
    }

    const handleReorder = (reordered) => {
        if (!entities) return false
        const changed = []
        const entitiesById = {}
        entities.forEach((entity) => {
            entitiesById[entity.id] = entity
        })
        const changedById = {}
        let entity
        for (let i = 0; i < reordered.length; i++) {
            const item = reordered[i]
            entity = entitiesById[item.id]
            if (entity && (
                entity.categoryId !== item.categoryId ||
                entity.sort !== item.sort
            )) {
                changed.push(item)
                changedById[item.id] = item
            }
        }
        if (!changed.length) return false
        api.put('/event/' + eventId + '/entity', {
            entities: changed.map(entity => ({
                id: entity.id,
                categoryId: entity.categoryId,
                sort: entity.sort
            }))
        })
        .then(() => {
            setError()
        })
        .catch(setError)
        dispatch(updateEvent({
            entities: event.entities.map(e => {
                const r = changedById[e.id]
                if (r) {
                    return {
                        ...e,
                        categoryId: r.categoryId,
                        sort: r.sort
                    }
                } else {
                    return e
                }
            }).sort((a, b) => a.sort > b.sort ? 1 : a.sort < b.sort ? -1 : 0)
        }))
    }

    return entities ? (
        <>
            <EntityList
                feature={feature}
                entities={entities}
                categories={categories}
                liveMode={liveMode}
                readOnly={readOnly}
                onReorder={handleReorder}
                onEdit={setEditing}
            />
            {!liveMode && !readOnly && (
                <>
                    <Button className='mt-3' onClick={handleShowModal}>New</Button>
                    {feature.type !== 'segment' && <Categories featureId={featureId} />}
                </>
            )}
            <Modal centered show={modal === 'new'} onHide={handleHideModal} size='lg'>
                <Modal.Header>
                    New
                </Modal.Header>
                <Modal.Body>
                    <EntityForm featureId={featureId} onSubmit={handleNew} onReset={handleHideModal} error={error} />
                </Modal.Body>
            </Modal>
            <Modal centered show={!!editing} onHide={handleCancelEdit} size='lg'>
                <Modal.Header>
                    Edit
                </Modal.Header>
                <Modal.Body>
                    <EntityForm featureId={featureId} values={editing} onSubmit={handleEdit} onReset={handleCancelEdit} onDelete={handleDelete} error={error} />
                </Modal.Body>
            </Modal>
        </>
    ) : error ? (
        <Alert variant='warning'>{error.message}</Alert>
    ) : (
        <Card body><Spinner animation='border' className='text-muted' /></Card>
    )

}

export default FeatureScreen