import { useState } from 'react'
import { Button } from 'react-bootstrap'
import { Copy, CheckSquare } from 'react-feather'

const CopyButton = ({ text, size, className, style }) => {

    const [copied, setCopied] = useState()

    const handleCopy = () => {
        navigator.clipboard.writeText(text)
        setCopied(true)
        setTimeout(() => setCopied(), 2000)
    }

    return text && navigator.clipboard ? (
        <Button size={size} style={style} className={className} variant='link' onClick={handleCopy}>
            {copied ? (
                <div className='small text-muted'>
                    <CheckSquare style={{ height: '1rem', width: '1rem' }} /> Copied
                </div>
            ) : (
                <div className='small'>
                    <Copy style={{ height: '1rem', width: '1rem' }} /> Copy
                </div>
            )}
        </Button>
    ) : null

}

export default CopyButton