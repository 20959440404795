import { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import { useSelector } from 'react-redux'
import { Row, Col, Button, Form } from 'react-bootstrap'
import FormGroup from 'components/form-group'
import Field from 'components/formik/field'
import DeleteButton from 'components/confirm-delete'
import ImageInput from 'components/image-input'
import * as yup from 'yup'

const schema = yup.object().shape({
    name: yup.string().required(),
    detail: yup.string().nullable().optional(),
    description: yup.string().nullable().optional(),
    imageUrl: yup.string().nullable().optional(),
    categoryId: yup.string().uuid().nullable().optional()
})

const defaults = (values) => ({
    name: values?.name || '',
    detail: values?.detail || '',
    description: values?.description || '',
    imageUrl: values?.imageUrl || null,
    categoryId: values?.categoryId || null
})

const EntityForm = ({ featureId, values, onSubmit, onReset, onDelete }) => {

    const event = useSelector(state => state.event)
    const feature = event?.features?.find(f => f.id === featureId)
    const categories = event?.categories?.filter(c => c.featureId === featureId) // ?.sort((a, b) => a.sort > b.sort ? 1 : a.sort < b.sort ? -1 : 0)
    const entityId = values?.id

    const handleSubmit = values => {
        onSubmit(values)
    }

    const handleDelete = () => {
        if (!!entityId && typeof onDelete === 'function') onDelete(entityId)
    }

    const initialValues = useMemo(() => {
        return defaults(values)
    }, [values])

    const canHaveDescription = ['speaker', 'startup', 'sponsor'].indexOf(feature?.type) > -1
    const canHaveImage = ['speaker', 'startup', 'sponsor'].indexOf(feature?.type) > -1
    const canHaveLink = ['startup', 'sponsor'].indexOf(feature?.type) > -1
    const canHaveContact = ['startup', 'sponsor'].indexOf(feature?.type) > -1

    return (
        <Formik initialValues={initialValues} onSubmit={handleSubmit} onReset={onReset} validationSchema={schema} validateOnChange={false} validateOnBlur={false}>
            {({ values, setFieldValue, isValid, handleSubmit, handleReset }) => (
                <form onSubmit={handleSubmit} onReset={handleReset} noValidate autoComplete='off'>
                    <Row xs={1} sm={2}>
                        <Col>
                            <Field label='Name' type='text' name='name' />
                        </Col>
                        <Col>
                            <Field label='Detail' type='text' name='detail' />
                        </Col>
                        {canHaveImage && (
                            <Col>
                                <FormGroup>
                                    <label className='d-block form-label'>Image</label>
                                    <ImageInput value={{ url: values.imageUrl }} onChange={image => setFieldValue('imageUrl', image?.url || null)} />
                                </FormGroup>
                                {/* <Field label='Image' type='url' name='imageUrl' placeholder='https://' /> */}
                            </Col>
                        )}
                        {categories?.length > 0 && (
                            <Col>
                                <Field label='Category' type='select' name='categoryId' disabled={!categories?.length}>
                                    <option />
                                    {categories?.map(category => (
                                        <option key={category.id} value={category.id}>{category.name}</option>
                                    ))}
                                </Field>
                            </Col>
                        )}
                    </Row>
                    {canHaveDescription && <Field label='Description' type='textarea' name='description' rows={4} style={{ resize: 'none' }} />}
                    {canHaveLink && (
                        <>
                            <Form.Label>External link</Form.Label>
                            <div className='small'>
                                <Row xs={1} sm={3}>
                                    <Col>
                                        <Field label='Text' type='text' name='linkLabel' size='sm' />
                                    </Col>
                                    <Col>
                                        <Field label='URL' type='url' name='linkUrl' size='sm' placeholder='https://' />
                                    </Col>
                                </Row>
                            </div>
                        </>
                    )}
                    {canHaveContact && (
                        <>
                            <Form.Label>Contact</Form.Label>
                            <div className='small'>
                                <Row xs={1} sm={3}>
                                    <Col>
                                        <Field label='Name' type='text' name='contactName' size='sm' />
                                    </Col>
                                    <Col>
                                        <Field label='Email' type='email' name='contactEmail' size='sm' />
                                    </Col>
                                    <Col>
                                        <FormGroup>
                                            <label className='small form-label d-block'>Image</label>
                                            <ImageInput value={{ url: values.contactImageUrl }} onChange={image => setFieldValue('contactImageUrl', image?.url || null)} />
                                        </FormGroup>
                                        {/* <Field label='Image' type='url' name='contactImageUrl' size='sm' placeholder='https://' /> */}
                                    </Col>
                                </Row>
                            </div>
                        </>
                    )}
                    <div className='mt-4'>
                        {!!entityId && <DeleteButton variant='link' className='float-end text-danger' onClick={handleDelete}>Delete</DeleteButton>}
                        <Button variant='primary' type='submit' disabled={!isValid}>Save</Button>
                        <Button variant='link' type='reset'>Cancel</Button>
                    </div>
                </form>
            )}
        </Formik>
    )

}

EntityForm.propTypes = {
    featureId: PropTypes.string.isRequired,
    values: PropTypes.object,
    onSubmit: PropTypes.func.isRequired,
    onReset: PropTypes.func.isRequired,
    onDelete: PropTypes.func
}

export default EntityForm