export const LOAD_EVENT = 'engage/event/LOAD'
export const UPDATE_EVENT = 'engage/event/UPDATE'
export const CLEAR_EVENT = 'engage/event/CLEAR'

const initialState = () => null

const reducer = (state = initialState(), { type, data }) => {
    switch (type) {
        case LOAD_EVENT:
            return {
                ...data
            }
        case UPDATE_EVENT:
            if (!data || !state) return state
            return {
                ...state,
                ...data
            }
        case CLEAR_EVENT:
            return null
        default:
            return state
    }
}

export default reducer

export const loadEvent = eventData => {
    return {
        type: LOAD_EVENT,
        data: eventData
    }
}

export const updateEvent = eventData => {
    return {
        type: UPDATE_EVENT,
        data: eventData
    }
}

export const clearEvent = () => {
    return {
        type: CLEAR_EVENT
    }
}