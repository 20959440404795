import { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import { Button } from 'react-bootstrap'
import Field from 'components/formik/field'
import DeleteButton from 'components/confirm-delete'
import * as yup from 'yup'

const schema = yup.object().shape({
    name: yup.string().required().label('Name'),
    type: yup.string().oneOf(['segment', 'speaker', 'sponsor', 'startup']).required().label('Type'),
    categories: yup.array().label('Categories').of(yup.object().shape({
        id: yup.string().uuid(),
        name: yup.string().required().label('Category'),
        sort: yup.number()
    }))
})

const defaults = (values) => ({
    name: values?.name || '',
    type: values?.type || 'segment',
    categories: values?.categories || []
})

const FeatureForm = ({ values, onSubmit, onReset, onDelete }) => {

    const handleSubmit = values => {
        onSubmit(values)
    }

    const handleDelete = () => {
        if (!!values.id && typeof onDelete === 'function') onDelete(values.id)
    }

    const initialValues = useMemo(() => {
        return defaults(values)
    }, [values])

    return (
        <>
            <Formik initialValues={initialValues} onSubmit={handleSubmit} onReset={onReset} validationSchema={schema} validateOnChange={false} validateOnBlur={false}>
                {({ values, isValid, handleSubmit, handleReset }) => (
                    <form onSubmit={handleSubmit} onReset={handleReset} noValidate autoComplete='off'>
                        {/* <Row sm={2}>
                            <Col> */}
                                <Field type='text' name='name' label='Name' className='mb-2' />
                            {/* </Col>
                            <Col> */}
                                <Field type='select' name='type' label='Type' className='mb-2' disabled={!!values.id}>
                                    <option value='segment'>Segments</option>
                                    <option value='speaker'>Speakers</option>
                                    <option value='sponsor'>Sponsors</option>
                                    <option value='startup'>Startups</option>
                                    {/* <option value='session'>Sessions</option> */}
                                </Field>
                            {/* </Col>
                        </Row> */}
                        {/* <Form.Label>
                            Categories
                            {(!values.categories || values.categories.length < 12) && (
                                <Button
                                    size='sm'
                                    variant='link'
                                    className='p-0 m-0 ms-1'
                                    style={{ verticalAlign: 'top' }}
                                    onClick={() => setFieldValue('categories', values.categories?.length > 0 ? [...values.categories, { id: uuidv4(), name: '', sort: values.categories.length }] : [{ id: uuidv4(), name: '', sort: 0 }])}
                                >
                                    <PlusCircle style={{ height: '1.25rem', width: '1.25rem' }} />
                                </Button>
                            )}
                        </Form.Label> */}
                        {/* <ReactSortable tag={ListGroup} list={values.categories || []} setList={newCategories => setFieldValue('categories', newCategories)}>
                            {values.categories?.map((category, index) => (
                                <ListGroup.Item key={category.id} className='d-flex p-2 position-relative align-items-center'>
                                    <AlignJustify style={{ color: '#ccc', height: '1.5rem', width: '1.5rem', cursor: 'grab' }} />
                                    <div className='flex-grow-1 px-2'>
                                        <Field type='text' name={`categories[${index}].name`} className='m-0' size='sm' />
                                    </div>
                                    <Button size='sm' className='p-0 text-danger' variant='link' style={{ lineHeight: 0 }} onClick={() => {
                                        setFieldValue('categories', values.categories.filter((f, i) => i !== index))
                                    }}>
                                        <XCircle style={{ height: '1.25rem', width: '1.25rem' }} />
                                    </Button>
                                </ListGroup.Item>
                            ))}
                        </ReactSortable> */}
                        <div className='mt-4'>
                            {!!values.id && <DeleteButton variant='link' className='float-end text-danger' onClick={handleDelete}>Delete</DeleteButton>}
                            <Button variant='primary' type='submit' disabled={!isValid}>Save</Button>
                            <Button variant='link' type='reset'>Cancel</Button>
                        </div>
                    </form>
                )}
            </Formik>
        </>
    )

}

FeatureForm.propTypes = {
    values: PropTypes.object,
    onSubmit: PropTypes.func.isRequired,
    onReset: PropTypes.func.isRequired,
    onDelete: PropTypes.func
}

export default FeatureForm