import { Form } from 'react-bootstrap'
import { useField } from 'formik'

const FormikCheck = props => {
    const [field, meta] = useField(props) // , helpers
    return (
        <>
            <Form.Check {...field} {...props} isInvalid={!!meta.error} id={props.id || props.type + '_' + props.name} />
        </>
    )
}

export default FormikCheck