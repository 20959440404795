import { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'

const DeleteButton = ({ children, onClick, ...props }) => {
    
    const [showModal, setShowModal] = useState()

    const handleDelete = () => {
        setShowModal()
        onClick()
    }

    return (
        <>
            <Button {...props} onClick={() => setShowModal(true)}>{children}</Button>
            <Modal centered size='sm' show={showModal} onHide={() => setShowModal()}>
                <Modal.Body>
                    <p>Are you sure you want to delete this?</p>
                    <div>
                        <Button variant='danger' onClick={handleDelete}>Delete</Button>
                        <Button variant='link' onClick={() => setShowModal()}>Cancel</Button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )

}

export default DeleteButton