import { useState } from 'react'
import { Button, Alert, Modal, ModalHeader, ModalBody, ModalFooter } from 'react-bootstrap'
import useActions from 'hooks/use-actions'
import useAPI from 'hooks/use-api'
import { useDispatch } from 'react-redux'
import { updateEvent } from 'store/event'

const ResetTestDataButton = ({ event }) => {
    
    const [modal, setModal] = useState()
    const [error, setError] = useState()
    const actions = useActions()
    const dispatch = useDispatch()
    const api = useAPI('/admin')

    const handleResetTestData = async () => {
        api.post(`/event/${event.id}/reset`)
        .then(() => {
            dispatch(updateEvent({
                guests: [],
                actions: []
            }))
            setError()
            setModal()
        })
        .catch(setError)
    }

    const testData = actions?.length > 0 || event?.guests?.length > 0

    return (
        <>
            <Button variant='link' onClick={() => setModal(true)} className={'px-0 ' + (testData ? 'text-danger' : 'text-muted')} disabled={!testData}>Reset test data</Button>
            <Modal show={!!modal} onHide={() => setModal()} centered>
                <ModalHeader>Reset test data</ModalHeader>
                <ModalBody>
                    CAUTION: This will permanently erase any guests and actions that have been created on this event.
                    {error && <Alert variant='danger' className='small'>{error.message}</Alert>}
                </ModalBody>
                <ModalFooter>
                    <Button variant='danger' onClick={handleResetTestData}>Reset</Button>
                    <Button variant='link' onClick={() => setModal()}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </>
    )

}

export default ResetTestDataButton