import { useSelector } from 'react-redux'

const useActions = (featureId, type, entityId) => {
    
    const actions = useSelector(state => state.event?.actions) // ?.filter(action => actionType ? action.type === actionType : true))
    if (!actions) return []

    return featureId || type || entityId ? actions.filter(action => {
        if (featureId && action.featureId !== featureId) return false
        if (type && action.type !== type) return false
        if (entityId && action.entityId !== entityId) return false
        let match = true
        // if (actionData) {
        //     Object.keys(actionData).forEach(key => {
        //         if (action.data[key] !== actionData[key]) match = false
        //     })
        // }
        return match
    }) : actions

}

export default useActions