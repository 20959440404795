import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Dropdown } from 'react-bootstrap'
import { Menu as MenuIcon } from 'react-feather'
import { clearEvent } from 'store/event'
import { clearUser } from 'store/user'
import { authLogout } from 'store/auth'
import style from './style.module.css'

const Menu = () => {

    const { t } = useTranslation()
    const dispatch = useDispatch()
    
    const handleLogout = () => {
        dispatch(clearEvent())
        dispatch(clearUser())
        dispatch(authLogout())
    }

    return (
        <>
            <Dropdown>
                <Dropdown.Toggle variant='light' id='menuDropdown' className={style.menuToggle}>
                    <MenuIcon />
                </Dropdown.Toggle>
                <Dropdown.Menu className={style.menu + ' dropdown-menu-end'}>
                    <Dropdown.Item onClick={handleLogout}>{t('Logout')}</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </>
    )

}

export default Menu