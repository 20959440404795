import { useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Button, Modal, ListGroup } from 'react-bootstrap'
import { ReactSortable } from 'react-sortablejs'
import { X, AlignJustify, PlusCircle } from 'react-feather'
import { updateEvent } from 'store/event'
import useAPI from 'hooks/use-api'
import FeatureForm from 'components/forms/feature'
import ConfirmDelete from 'components/confirm-delete'
import { EditText } from 'react-edit-text'
import 'react-edit-text/dist/index.css'

const FeaturesSection = ({ event, liveMode, readOnly }) => {

    const eventId = event?.id
    const features = event?.features // ?.map(f => f).sort((a, b) => a.sort > b.sort ? 1 : a.sort < b.sort ? -1 : 0) || []
    const [showNew, setShowNew] = useState()
    // const [showEdit, setShowEdit] = useState()
    const [error, setError] = useState()
    const dispatch = useDispatch()
    const api = useAPI('/admin')

    const toggleShowNew = () => setShowNew(show => !show)
    // const toggleShowEdit = () => setShowEdit(show => !show)

    const handleNew = values => {
        api.post('/event/' + eventId + '/feature', values)
        .then(feature => {
            dispatch(updateEvent({
                features: event.features ? [
                    ...event.features,
                    feature
                ] : [feature]
            }))
            toggleShowNew()
            setError()
        })
        .catch(setError)
    }

    const handleEdit = feature => {
        api.put('/event/' + eventId + '/feature/' + feature.id, feature)
        .then(feature => {
            dispatch(updateEvent({
                features: features.map(f => f.id === feature.id ? feature : f)
            }))
            setError()
        })
        .catch(setError)
    }

    const handleDelete = feature => {
        api.delete('/event/' + eventId + '/feature/' + feature.id)
        .then(() => {
            dispatch(updateEvent({
                features: event.features.filter(f => f.id !== feature.id)
            }))
            setError()
        })
        .catch(setError)
    }

    const handleReorder = sorted => {
        if (!features) return false
        const ids = sorted.map(f => f.id)
        const old = features.map(f => f.id)
        let changed = false
        ids.forEach((id, index) => {
            if (changed) return
            if (index !== old.indexOf(id)) changed = true
        })
        if (!changed) return false
        api.put('/event/' + eventId + '/feature', { features: sorted.map((f, i) => ({ id: f.id, sort: i })) })
        .then(() => {
            dispatch(updateEvent({
                features: features.map(f => ids.indexOf(f.id) > -1 ? { ...f, sort: ids.indexOf(f.id) } : f).sort((a, b) => a.sort > b.sort ? 1 : a.sort < b.sort ? -1 : 0)
            }))
            setError()
        })
        .catch(setError)
    }

    return (
        <>
            <h4>
                Features
                {!liveMode && !readOnly && (!features || features.length < 4) && <Button size='sm' variant='link' className='p-0 m-0 ms-1 align-top' onClick={toggleShowNew}>
                    <PlusCircle style={{ height: '1.625rem', width: '1.625rem' }} />
                </Button>}
            </h4>
            {liveMode || readOnly ? (
                <ListGroup>
                    {features?.map(feature => (
                        <ListGroup.Item key={feature.id} className='d-flex p-2 align-items-center d-flex align-items-center' action as={Link} to={'/event/' + eventId + '/feature/' + feature.id}>
                            <div className='flex-shrink-0'>{feature.name}</div>
                            <div className='ms-1 text-muted flex-grow-1'>({feature.type})</div>
                        </ListGroup.Item>
                    ))}
                </ListGroup>
            ) : (
                <ReactSortable tag={ListGroup} variant='flush' list={features || []} setList={handleReorder}>
                    {features?.map(feature => {
                        const count = event.entities.filter(e => e.featureId === feature.id).length
                        return (
                            <ListGroup.Item key={feature.id} className='d-flex p-2 align-items-center'>
                                <AlignJustify style={{ color: '#ccc', height: '1.5rem', width: '1.5rem', cursor: 'grab' }} />
                                <div className='flex-grow-1 mx-2 d-flex align-items-center'>
                                    <EditText defaultValue={feature.name} className='my-n2 flex-shrink-0' onSave={e => handleEdit({ ...feature, name: e.value })} />
                                    <div className='ms-1 me-n1 flex-grow-1 text-muted'>({feature.type})</div>
                                </div>
                                <div className='mx-2'>
                                    {count}
                                </div>
                                <ConfirmDelete disabled={count} onClick={() => handleDelete(feature)} variant='link' className={'p-0 ' + (count ? 'text-muted' : 'text-danger')} style={{ lineHeight: 0 }}>
                                    <X style={{ height: '1.25rem', width: '1.25rem' }} />
                                </ConfirmDelete>
                            </ListGroup.Item>
                        )
                    })}
                </ReactSortable>
            )}
            <Modal centered show={showNew} onHide={toggleShowNew} size='sm'>
                <Modal.Header>
                    New Feature
                </Modal.Header>
                <Modal.Body>
                    <FeatureForm onSubmit={handleNew} onReset={toggleShowNew} error={error} />
                </Modal.Body>
            </Modal>
            {/* <Modal centered show={showEdit} onHide={toggleShowEdit} size='sm'>
                <Modal.Header>
                    Edit Feature
                </Modal.Header>
                <Modal.Body>
                    <FeatureForm values={showEdit} onSubmit={handleEdit} onReset={toggleShowEdit} error={error} />
                </Modal.Body>
            </Modal> */}
        </>
    )

}

export default FeaturesSection