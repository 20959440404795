import { useState, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button, Form } from 'react-bootstrap'
import { AlignJustify } from 'react-feather'
import { updateEvent } from 'store/event'
import useActions from 'hooks/use-actions'
import useAPI from 'hooks/use-api'
// import useSocket from 'hooks/use-socket'

const StartupListItem = ({ entity, liveMode, readOnly, onClick }) => {

    const event = useSelector(state => state.event)
    const [error, setError] = useState()
    const dispatch = useDispatch()
    // const socket = useSocket()
    const api = useAPI('/admin')

    const startup = entity
    const startupId = startup?.id
    const eventId = event?.id
    // const startups = event?.entities?.filter(e => e.featureId === entity?.featureId)
    const actions = useActions(entity?.featureId, 'vote', startupId)

    const handleClick = () => {
        if (!onClick || typeof onClick !== 'function') return false
        onClick(entity)
    }

    const results = useMemo(() => {
        const uniqueVotes = []
        const comments = []
        let resultCount = 0
        let ratingScore = 0
        actions.filter(action => {
            if (action.featureId !== startup?.featureId || action.type !== 'vote' || action.entityId !== startup?.id || !action.data) return false
            if (uniqueVotes.indexOf(action.guestId) > -1) return false
            uniqueVotes.push(action.guestId)
            if (action.data.comments) comments.push(action.data.comments)
            return true
        }).forEach(action => {
            resultCount++
            ratingScore += Number(action.data.rating)
        })
        return {
            rating: ratingScore ? Math.round((ratingScore / resultCount) * 10) / 10 : 0,
            count: uniqueVotes.length,
            comments
        }
    }, [actions, startup])

    const handleWinner = e => {
        const checked = e.target.checked
        api.put('/event/'+ eventId + '/entity/' + startupId, {
            badge: checked ? 'winner' : null
        })
        .then(() => {
            dispatch(updateEvent({
                ...event,
                entities: event.entities.map(s => s.id === startupId ? ({ ...s, badge: checked ? 'winner' : null }) : s)
            }))
        })
        .catch(setError)
    }

    const handleClosed = e => {
        const checked = e.target.checked
        api.put('/event/'+ eventId + '/entity/' + startupId, {
            data: checked ? results : null
        })
        .then(() => {
            dispatch(updateEvent({
                ...event,
                entities: event.entities.map(s => s.id === startupId ? ({ ...s, data: checked ? results : null }) : s)
            }))
        })
        .catch(setError)
    }

    const handleActive = e => {
        const active = e.target.checked
        api.put('/event/'+ eventId + '/entity/' + startupId, {
            status: active ? 'active' : null
        })
        .then(async () => {
            if (active) {
                for (let i = 0; i < event.entities.length; i++) {
                    const entity = event.entities[i]
                    if (
                        entity.featureId === startup.featureId &&
                        entity.id !== startupId &&
                        entity.status
                    ) {
                        await api.put('/event/'+ eventId + '/entity/' + entity.id, {
                            status: null
                        })
                    }
                }
            }
            dispatch(updateEvent({
                ...event,
                entities: event.entities.map(s => s.id === startupId ? ({ ...s, status: active ? 'active' : null }) : s.featureId === startup.featureId ? ({ ...s, status: null }) : s)
            }))
        })
        .catch(setError)
    }

    return (
        <div>
            <div className='d-flex p-2 align-items-center'>
                {!liveMode && !readOnly && <AlignJustify style={{ color: '#ccc', height: '1.5rem', width: '1.5rem', cursor: 'grab' }} />}
                {!liveMode && !readOnly ? (
                    <Button onClick={handleClick} variant='link' className='m-0 p-0 flex-grow-1 mx-2 text-dark text-start border-0'>
                        {entity.name}
                        {entity.detail && <div className='d-inline small text-muted ms-2'>{entity.detail}</div>}
                    </Button>
                ) : (
                    <div className='flex-grow-1'>
                        <div>
                            {entity.name}
                            {entity.detail && <div className='d-inline small text-muted ms-2'>{entity.detail}</div>}
                        </div>
                        {actions && results ? (
                            <div className='small text-muted'>
                                Rating: {Math.round(results.rating * 10) /  10}, Votes: {results.count}
                            </div>
                        ) : null}
                    </div>
                )}
                {liveMode && !readOnly && (
                    <>
                        <div className='ps-2'>
                            <Form.Check id={'startup-active-' + startup.id} label='Active' type='checkbox' onChange={handleActive} checked={!!startup.status} />
                        </div>
                        <div className='ps-2'>
                            <Form.Check id={'startup-closed-' + startup.id} label='Closed' type='checkbox' onChange={handleClosed} checked={!!startup.data} />
                        </div>
                        <div className='ps-2'>
                            <Form.Check id={'startup-winner-' + startup.id} label='Winner' type='checkbox' onChange={handleWinner} checked={!!startup.badge} />
                        </div>
                    </>
                )}
            </div>
            {liveMode && !!startup.status && results.comments?.map((comment, index) => (
                <div className='bg-light small p-2' key={index}>{comment}</div>
            ))}
        </div>
    )

    // return (
    //     <ListGroup.Item key={startup.id} className='d-flex p-2 align-items-center'>
    //         <div className='flex-grow-1'>
    //             {startup.name}
    //             {actions && results ? (
    //                 <div className='small text-muted'>
    //                     Quality: {Math.round(results.quality * 10) /  10}, Relevance: {Math.round(results.relevance * 10) /  10}, Votes: {results.count}
    //                 </div>
    //             ) : null}
    //         </div>
    //         <div className='ps-2'>
    //             <Form.Check id={'startup-closed-' + startup.id} label='Closed' type='checkbox' onChange={handleClosed.bind(null, startup.id)} checked={!!startup.data} />
    //         </div>
    //         <div className='ps-2'>
    //             <Form.Check id={'startup-winner-' + startup.id} label='Winner' type='checkbox' onChange={handleWinner.bind(null, startup.id)} checked={!!startup.badge} />
    //         </div>
    //     </ListGroup.Item>
    // )

}

export default StartupListItem