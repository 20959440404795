// import { Link } from 'react-router-dom'
import style from './style.module.css'
import Menu from './menu'

import engageLogo from 'assets/engage-logo.png'

const Header = ({ loggedIn = true }) => {
  
    return (
        <div className={style.header}>
          <div className={style.headerBrand}>
            <img src={engageLogo} alt='Engage' title='Engage' className={style.headerLogo} />
          </div>
          <div className={style.headerSpacer} />
          {loggedIn && <ul className={style.headerNav}>
            <li className={style.headerNavItem}>
              <Menu />
            </li>
          </ul>}
        </div>
    )

}

export default Header