import { useEffect, useCallback, Suspense, lazy } from 'react'
import PropTypes from 'prop-types'
import { Routes, Route, Navigate, useSearchParams, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Container, Spinner } from 'react-bootstrap'
import { authLogin, authLogout } from 'store/auth'
import AuthHeader from './header'
import AuthFooter from './footer'
import useAPI from 'hooks/use-api'

const LoginWithCode = lazy(() => import('./login-code'))
const LoginWithPassword = lazy(() => import('./login-password'))
// const Signup = lazy(() => import('./signup'))
const Recover = lazy(() => import('./recover'))
const Verify = lazy(() => import('./verify'))

const AuthWall = ({ children }) => {

  const auth = useSelector((state) => state.auth)
  const [searchParams] = useSearchParams()
  const emailAddress = searchParams.get('email') || ''
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const api = useAPI('/auth')

  useEffect(() => {
    if (auth?.token) {
      api.post('/refresh', { token: auth.token })
      .then((result) => {
        dispatch(authLogin({ token: result.token }))
      })
      .catch((error) => {
        if (error.status === 401) {
          dispatch(authLogout())
        }
        console.error(error)
      })
    }
  }, [])

  const loginWithToken = useCallback((token) => {
    return api.post('/login', { token })
    .then((result) => {
      dispatch(authLogin({ token: result.token }))
      navigate('/')
    })
  }, [dispatch, api, navigate])

  return auth?.token ? (
    children
  ) : (
    <div className='d-flex flex-column justify-content-between align-items-start' style={{ minHeight: '100vh', backgroundColor: '#f2f2f2' }}>
      <AuthHeader />
      <Container>
        <div style={{ maxWidth: '24rem' }}>
          <Suspense fallback={<div className='text-center py-5'>
            <Spinner animation='border' variant='secondary' />
          </div>}>
            <Routes>
              {/* <Route exact path='/' element={<LoginWithCode onTokenReceived={loginWithToken} emailAddress={emailAddress} initMode='code' />} /> */}
              {/* <Route exact path='/login' element={<LoginWithCode onTokenReceived={loginWithToken} emailAddress={emailAddress} initMode='code' />} /> */}
              <Route path='/' element={<Navigate to='/login/code' />} />
              <Route path='/login' element={<Navigate to='/login/code' />} />
              <Route exact path='/login/code' element={<LoginWithCode onTokenReceived={loginWithToken} emailAddress={emailAddress} />} />
              <Route exact path='/login/password' element={<LoginWithPassword onTokenReceived={loginWithToken} emailAddress={emailAddress} />} />
              {/* <Route exact path='/signup' element={<Signup />} /> */}
              <Route exact path='/verify' element={<Verify emailAddress={emailAddress} onTokenReceived={loginWithToken} />} />
              <Route exact path='/recover' element={<Recover emailAddress={emailAddress} />} />
              <Route path='*' element={<Navigate to='/' />} />
            </Routes>
          </Suspense>
        </div>
      </Container>
      <AuthFooter />
    </div>
  )
}

export default AuthWall

AuthWall.propTypes = {
  children: PropTypes.node
}