import { Suspense } from 'react' // useMemo
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { Provider} from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import Loading from 'components/loading'
import Layout from 'components/layout'
// import { SocketProvider } from 'hooks/use-socket'
// import io from 'socket.io-client'
import { store, persistor } from './store'
import './style/index.scss'

import AuthWall from 'components/auth-wall'
import EventListScreen from 'screens/event-list'
import EventScreen from 'screens/event'
import Analytics from 'components/analytics'

const App = () => {

  // const socket = useMemo(() => {
  //   const socket = io.connect(
  //       process.env.REACT_APP_SOCKET_URL,
  //       {} // options
  //   )
  //   return socket
  // }, [])
  
  return (
    <Suspense fallback={<Loading />}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter>
            <AuthWall>
              {/* <SocketProvider socket={socket}> */}
                <Layout>
                  <Routes>
                    <Route exact path='/' element={<EventListScreen />} />
                    <Route path='/event/:eventId/*' element={<EventScreen />} />
                  </Routes>
                </Layout>
              {/* </SocketProvider> */}
            </AuthWall>
            <Analytics />
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </Suspense>
  )
  
}

export default App