import { useState } from 'react'
import { ListGroup, Button } from 'react-bootstrap'
import { AlignJustify } from 'react-feather'

const SpeakerListItem = ({ entity, liveMode, readOnly, onClick }) => {

    const handleClick = () => {
        if (!onClick || typeof onClick !== 'function') return false
        onClick(entity)
    }

    return (
        <div className='d-flex p-2 align-items-center'>
            {!liveMode && !readOnly && <AlignJustify style={{ color: '#ccc', height: '1.5rem', width: '1.5rem', cursor: 'grab' }} />}
            {!liveMode && !readOnly ? (
                <Button onClick={handleClick} variant='link' className='m-0 p-0 flex-grow-1 mx-2 text-dark text-start border-0'>
                    {entity.name}
                    {entity.detail && <div className='d-inline small text-muted ms-2'>{entity.detail}</div>}
                </Button>
            ) : (
                <div className='flex-grow-1'>
                    {entity.name}
                    {entity.detail && <div className='d-inline small text-muted ms-2'>{entity.detail}</div>}
                </div>
            )}
        </div>
    )

}

export default SpeakerListItem