import { useSelector } from 'react-redux'
import { Card, Table } from 'react-bootstrap'
// import useActions from 'hooks/use-actions'
import { format } from 'date-fns'

const ActionsScreen = () => {

    const event = useSelector(state => state.event)
    const actions = event?.actions
    // const triggers = event?.triggers
    // const actions = useActions(event?.id)

    const featureNames = {}
    event?.features?.forEach(feature => {
        featureNames[feature.id] = feature.name
    })
    const entityNames = {}
    event?.entities?.forEach(entity => {
        entityNames[entity.id] = entity.name
    })

    return (
        <>
            <Card className='mb-3'>
                {actions && actions.length > 0 && <Table responsive className='text-nowrap m-0'>
                    <thead className='bg-secondary text-white'>
                        <tr>
                            <th>Guest</th>
                            <th>Action</th>
                            <th>Entity</th>
                            <td>Data</td>
                            <th>Time</th>
                        </tr>
                    </thead>
                    <tbody>
                        {actions && actions.map(action => (
                            <tr key={action.id}>
                                <td>{event.guests?.find(guest => guest.id === action.guestId)?.name}</td>
                                <td>{featureNames[action.featureId]}:{action.type}</td>
                                <td>{entityNames[action.entityId]}</td>
                                <td className='small text-muted text-wrap lh-sm'>{action.data && JSON.stringify(action.data)}</td>
                                <td>{format(new Date(action.createdAt), 'PP p')}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>}
            </Card>
            {/* <Card>
                <Card.Header className='bg-secondary text-white fw-bold p-2'>
                    <Button variant='light' size='sm' className='float-end my-n1 mx-n1 py-1 px-2 m-0'>New Trigger</Button>
                    Triggers
                </Card.Header>
                <ListGroup className='list-group-flush'>
                    {triggers && triggers.map(trigger => (
                        <ListGroup.Item className='small p-2' key={trigger.id}>
                            {JSON.stringify(trigger)}
                        </ListGroup.Item>
                    ))}
                </ListGroup>
            </Card> */}
        </>
    )

}

export default ActionsScreen