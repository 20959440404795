import { useState, useEffect, useCallback } from 'react'
import { Row, Col, Card, Button, Form, Modal, Tabs, Tab, InputGroup, Alert } from 'react-bootstrap'
import FormGroup from 'components/form-group'
import loadImage from 'blueimp-load-image'
import { Formik } from 'formik'
import yup from 'lib/yup'
// import Croppie from 'croppie'
import 'croppie/croppie.css'
import { X, Image, Trash } from 'react-feather'
import DeleteButton from 'components/confirm-delete'
import { imgBaseUrl } from 'lib/cloudinary'
import useAPI from 'hooks/use-api'
import { useDropzone } from 'react-dropzone'

const maxWidth = 2560
const maxHeight = 2560

const ImageInput = ({ value, onChange, cropRatio = 1 }) => {
    
    const [error, setError] = useState()
    const [active, setActive] = useState()
    const [preview, setPreview] = useState()
    // const [selected, setSelected] = useState()
    // const [cropping, setCropping] = useState()
    const [images, setImages] = useState()
    const API = useAPI('/admin')

    useEffect(() => {
        API.get('/image')
        .then(setImages)
        .catch(setError)
    }, [])

    const handleRemove = () => onChange()
    const handleReplace = () => setActive(true)

    const handleUpload = files => {
		// const files = e.target.files
		const file = files[0]
        if (!file) return false
        const fileName = file.name.split('.')[0]
        const fileType = file.type
		if (['image/jpeg', 'image/png', 'image/svg+xml', + 'image/webp'].indexOf(fileType) === -1) {
			setError(new Error('Image must be in JPG, PNG, SVG, or WEBP format'))
		} else if (file.size > 1000000) {
			setError(new Error('Image cannot be larger than 1MB'))
		} else {
			loadImage(
			   	URL.createObjectURL(file),
                {
                    maxWidth,
                    maxHeight,
                    orientation: true,
                    canvas: true
                }
            )
            .then(({ image }) => {
                const dataURL = image.toDataURL(fileType)
                API.post('/image', { name: fileName, data: dataURL })
                .then(image => {
                    setImages(images => [...images, image])
                    setActive()
                    setError()
                    onChange(image)
                })
                .catch(setError)
                // setPreview(dataURL)
                // setCropping(true)
            })
            .catch(setError)
		}
	}

    const handleFetch = async ({ url }) => {
        const urlSplit = url.split('?')[0].split('.')
        const filePath = urlSplit[urlSplit.length - 2].split('/')
        const fileName = filePath[filePath.length - 1]
        const fileExt = urlSplit[urlSplit.length - 1]
        if (['jpg', 'jpeg', 'png', 'svg', 'webp'].indexOf(fileExt) === -1) {
            return setError(new Error('Image must be in JPG, PNG, SVG, or WEBP format'))
        }
        API.post('/image', { name: fileName, url })
        .then(image => {
            setActive()
            setError()
            onChange(image)
        })
        .catch(setError)
    }

    const handleSelect = image => {
        setActive()
        setError()
        onChange(image)
    }

    const handleDelete = image => {
        API.delete('/image/' + image.id)
        .then(() => {
            setImages(images => images.filter(img => img.id !== image.id))
            setError()
        })
        .catch(setError)
    }

    const onDrop = useCallback(acceptedFiles => {
        // Do something with the files
        console.log(acceptedFiles)
        handleUpload(acceptedFiles)
    }, [])
    
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        maxFiles: 1,
        maxSize: 5242880, // 5mb
        accept: {
            'image/jpeg': ['.jpg', '.jpeg'],
            'image/png': ['.png'],
            'image/svg+xml': ['.svg'],
            'image/webp': ['.webp']
        }
    })

    // const handleCrop = cropped => {
    //     setPreview(cropped)
    //     setCropping()
    //     API.post('/image', { data: cropped })
    //     .then(image => {
    //         onChange(image)
    //         setActive()
    //         setError()
    //     })
    //     .catch(setError)
    // }

    // const handleSkip = () => {
    //     setCropping()
    //     API.post('/image', { data: preview })
    //     .then(image => {
    //         onChange(image)
    //         setActive()
    //         setError()
    //     })
    //     .catch(setError)
    // }

    return (
        <>
            {value?.url ? (
                <div className='d-flex align-items-center text-muted'>
                    <img src={imgBaseUrl + '/t_scale_150/' + value.url} alt='' style={{ height: 'auto', width: 'auto', maxHeight: '3rem', maxWidth: '9rem' }} />
                    <div className='ms-1'>
                        <Button variant='link' className='text-danger p-1' size='sm' onClick={handleRemove}>Remove</Button>
                        {/* <br /> */}|
                        <Button variant='link' size='sm' className='p-1' onClick={handleReplace}>Replace</Button>
                    </div>
                </div>
            ) : (
                <Button variant='secondary' size='sm' onClick={() => setActive(true)}>Add Image</Button>
            )}
            <Modal show={active} onHide={() => setActive()} centered size='xl'>
                <Button variant='link' className='p-2 text-muted position-absolute' style={{ top: 0, right: 0, lineHeight: 1 }} onClick={() => setActive()}><X /></Button>
                <Tabs defaultActiveKey='browse' id='image-upload-tabs'>
                    <Tab eventKey='browse' title='Library' className='bg-light' style={{ borderBottomLeftRadius: '0.25rem', borderBottomRightRadius: '0.25rem' }}>
                        {images?.length > 0 ? (
                            <div className='p-3' style={{ height: '40rem', maxHeight: '80vh', overflowY: 'scroll' }}>
                                <Row as='ul' className='list-unstyled g-2' xs={2} sm={3} lg={4} xl={5}>
                                    {images.map(image => (
                                        <Col as='li' key={image.id} className='d-flex'>
                                            <Card className='position-relative mb-1 flex-grow-1 d-flex bg-light shadow-sm'>
                                                <button className='m-0 p-0 border-0 bg-transparent text-start flex-grow-1 d-flex align-items-center' onClick={handleSelect.bind(null, image)} style={{ minHeight: '10rem' }}>
                                                    <img src={imgBaseUrl + '/t_scale_300/' + image.url} alt='' style={{
                                                        width: '100%',
                                                        borderTopLeftRadius: '0.25rem',
                                                        backgroundImage:  'repeating-linear-gradient(45deg, #e5e5e5 25%, transparent 25%, transparent 75%, #e5e5e5 75%, #e5e5e5), repeating-linear-gradient(45deg, #e5e5e5 25%, #ffffff 25%, #ffffff 75%, #e5e5e5 75%, #e5e5e5)',
                                                        backgroundPosition: '0 0, 10px 10px',
                                                        backgroundSize: '20px 20px'
                                                    }} />
                                                </button>
                                                <Card.Body className='p-1 flex-grow-0'>
                                                    <div className='overflow-hidden' style={{ fontSize: '0.75rem', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                                                        {image.name}.{image.format}
                                                    </div>
                                                </Card.Body>
                                                <DeleteButton size='sm' variant='link' className='bg-transparent text-muted position-absolute' style={{ padding: '0.25rem', lineHeight: 1, bottom: 0, right: 0 }} onClick={handleDelete.bind(null, image)}>
                                                    <Trash style={{ height: '0.875rem', width: '0.875rem' }} />
                                                </DeleteButton>
                                            </Card>
                                        </Col>
                                    ))}
                                </Row>
                            </div>
                        ) : (
                            <div className='p-3 d-flex text-muted flex-column align-items-center justify-content-center' style={{ height: '40rem', maxHeight: '80vh' }}>
                                <Image className='mb-2' style={{ height: '3rem', width: '3rem' }} />
                                No images yet
                            </div>
                        )}
                    </Tab>
                    <Tab eventKey='upload' title='Upload' className='bg-light' style={{ borderBottomLeftRadius: '0.25rem', borderBottomRightRadius: '0.25rem' }}>
                        {preview ? (
                            <div className='p-3 d-flex flex-column align-items-center justify-content-center' style={{ height: '40rem', maxHeight: '80vh' }}>
                                <img src={preview} alt='' style={{ maxWidth: '6rem', maxHeight: '4rem' }} />
                                <DeleteButton variant='link' onClick={() => setPreview()}><X /></DeleteButton>
                            </div>
                        ) : (
                            <div className='p-3 d-flex flex-column align-items-center justify-content-center' style={{ height: '40rem', maxHeight: '80vh', background: isDragActive ? '#e5e5e5' : 'transparent' }} {...getRootProps()}>
                                <input {...getInputProps()} />
                                <div className='mb-2 text-muted' style={{ fontSize: '1.25rem' }}>Drag and Drop or</div>
                                <Button as='div' size='lg' variant={isDragActive ? 'secondary' : 'primary'} className='shadow-md py-2 px-3'>
                                    Click to Browse
                                </Button>
                            </div>
                            // <div className='p-3 d-flex flex-column align-items-center justify-content-center' style={{ height: '40rem', maxHeight: '80vh' }}>
                            //     <Button size='lg' variant={isDragActive ? 'secondary' : 'primary'} as='label' className='position-relative overflow-hidden'>
                            //         Browse
                            //         <input type='file' accept='image/jpeg,image/png,image/svg+xml,image/webp' tabindex={-1} multiple={false} onChange={handleUpload} className='position-absolute' style={{ left: '-99999px' }} />
                            //     </Button>
                            // </div>
                        )}
                    </Tab>
                    <Tab eventKey='url' title='URL' className='bg-light' style={{ borderBottomLeftRadius: '0.25rem', borderBottomRightRadius: '0.25rem' }}>
                        <div className='p-3 d-flex flex-column align-items-center justify-content-center' style={{ height: '40rem', maxHeight: '80vh' }}>
                            <Formik initialValues={{ url: '' }} validationSchema={yup.object().shape({url:yup.string().url().required()})} onSubmit={handleFetch}>
                                {({ values, setFieldValue, handleSubmit }) => (
                                    <FormGroup>
                                        <label>Paste an image URL</label>
                                        <InputGroup>
                                            <Form.Control type='url' placeholder='https://' htmlSize='40' value={values.url} onChange={e => setFieldValue('url', e.target.value)} />
                                            <Button variant='primary' onClick={handleSubmit}>Fetch</Button>
                                        </InputGroup>
                                    </FormGroup>
                                )}
                            </Formik>
                        </div>
                    </Tab>
                </Tabs>
                {error && <Alert variant='warning' className='mb-0 px-3'>{error.message}</Alert>}
            </Modal>
            {/* <Modal show={cropping} onHide={() => setCropping()} centered>
                <Modal.Body>
                    <ImageCrop image={preview} onCrop={handleCrop} onSkip={handleSkip} />
                </Modal.Body>
            </Modal> */}
        </>
    )

}

// const ImageCrop = ({ image, onCrop, onSkip }) => {

//     const croppie = useRef()
//     const [c, setC] = useState()

//     const croppieOptions = {
//         showZoomer: true,
//         enableExif: true,
//         enableOrientation: false,
//         mouseWheelZoom: 'ctrl',
//         viewport: {
//             width: 300,
//             height: 300,
//             type: 'square'
//         },
//         boundary: {
//             width: '100%',
//             height: '50vh'
//         }
//     }

//     useLayoutEffect(() => {
//         setC(new Croppie(croppie.current, croppieOptions))
//     }, [])

//     useEffect(() => {
//         if (c && image) c.bind(image)
//     }, [c, image])

//     const handleCrop = () => {
//         c.result('base64').then(base64 => {
//             onCrop(base64)
//         })
//     }

//     const handleSkip = () => {
//         onSkip()
//     }

//     return (
//         <>
//             <div ref={croppie} />
//             <Button variant='primary' onClick={handleCrop}>Crop</Button>
//             <Button variant='link' onClick={handleSkip}>Skip</Button>
//         </>
//     )

// }

export default ImageInput