import { useEffect, useState } from 'react'
// import { Link, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Card, Table, ListGroup, Alert, Spinner } from 'react-bootstrap'
import { format } from 'date-fns'
import { updateEvent } from 'store/event'
import useAPI from 'hooks/use-api'

const GuestsScreen = () => {

    const event = useSelector(state => state.event)
    const eventId = event?.id
    const guests = event?.guests
    const [error, setError] = useState()
    const dispatch = useDispatch()
    const api = useAPI('/admin')

    // useEffect(() => {
    //     if (!event) return
    //     api.get('/event/' + eventId + '/guest')
    //     .then(guests => {
    //         dispatch(updateEvent({
    //             guests
    //         }))
    //         setError()
    //     })
    //     .catch(setError)
    // }, [])

    return guests ? (
        <Card className='overflow-hidden'>
            {guests.length > 0 && <Table responsive className='text-nowrap m-0'>
                <thead className='bg-secondary text-white'>
                    <tr>
                        <th>Name</th>
                        <th>Email</th>
                        {event.fields?.length > 0 && <th>{event.fields[0].label}</th>}
                        {event.fields?.length > 1 && <th>{event.fields[1].label}</th>}
                        <th>Joined</th>
                    </tr>
                </thead>
                <tbody>
                    {guests.map(guest => (
                        <tr key={guest.id}>
                            <td>{guest.name}</td>
                            <td><a href={'mailto://' + guest.email}>{guest.email}</a></td>
                            {event.fields?.length > 0 && <td>{guest.field1}</td>}
                            {event.fields?.length > 1 && <td>{guest.field2}</td>}
                            <td>{format(new Date(guest.joinedAt), 'PP p')}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>}
        </Card>
    ) : error ? (
        <Alert variant='warning'>{error.message}</Alert>
    ) : (
        <Card body><Spinner animation='border' /></Card>
    )

}

export default GuestsScreen