import { createStore, combineReducers } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import authReducer from './auth'
import userReducer from './user'
import eventReducer from './event'

const authPersistConfig = {
  key: 'auth',
  storage
}

const userPersistConfig = {
  key: 'user',
  storage
}

// const eventPersistConfig = {
//   key: 'event',
//   storage
// }

const rootReducer = combineReducers({
  auth: persistReducer(authPersistConfig, authReducer),
  user: persistReducer(userPersistConfig, userReducer),
  event: eventReducer // persistReducer(eventPersistConfig, eventReducer)
})

export const store = createStore(rootReducer)
export const persistor = persistStore(store)

export default store