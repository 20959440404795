import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Container, Row, Col, ListGroup, Alert, Button, Modal } from 'react-bootstrap'
import EventForm from 'components/forms/event'
import useAPI from 'hooks/use-api'

const EventListScreen = () => {

    const api = useAPI('/admin')
    const [events, setEvents] = useState()
    const [error, setError] = useState()

    useEffect(() => {
        api.get('/event')
        .then(setEvents)
        .catch(setError)
    }, [])

    const handleNewEvent = newEvent => {
        setEvents(events => [newEvent, ...events])
    }

    const rightNow = Date.now()

    return (
        <>
            <Container className='p-3'>
                <div>
                    <NewEvent onNewEvent={handleNewEvent} />
                    <h1>Events</h1>
                </div>
                {error ? <Alert variant='warning'>{error.message}</Alert> : (
                    <ListGroup>
                        {events?.length > 0 && events.map(event => {
                            const startDate = new Date(event.startsAt)
                            const endTime = new Date(event.endsAt).getTime()
                            const hasEnded = endTime < rightNow
                            return (
                                <ListGroup.Item key={event.id} as={Link} action to={'/event/' + event.id} className={hasEnded ? 'text-muted' : ''}>
                                    <Row>
                                        <Col>{event.name}</Col>
                                        <Col className='text-end'>{startDate.toLocaleDateString()}</Col>
                                    </Row>
                                </ListGroup.Item>
                            )
                        })}
                    </ListGroup>
                )}
            </Container>
        </>
    )
}

export default EventListScreen

const NewEvent = ({ onNewEvent }) => {

    const api = useAPI('/admin')
    const [error, setError] = useState()
    const [modal, setModal] = useState()

    const handleNewEvent = values => {
        api.post('/event', values)
        .then(event => {
            setError()
            setModal()
            if (onNewEvent) onNewEvent(event)
        })
        .catch(setError)
    }

    const handleShowModal = () => setModal('new')
    const handleHideModal = () => setModal()

    return (
        <>
            <Button className='float-end mt-1' onClick={handleShowModal}>New Event</Button>
            <Modal centered show={modal === 'new'} onHide={handleHideModal} size='lg'>
                <Modal.Header>
                    New Event
                </Modal.Header>
                <Modal.Body>
                    <EventForm onSubmit={handleNewEvent} onReset={handleHideModal} error={error} />
                    {error && <Alert variant='warning'>{error.message}</Alert>}
                </Modal.Body>
            </Modal>
        </>
    )
}