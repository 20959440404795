import { useEffect } from 'react'
import LogRocket from 'logrocket'
import { useSelector } from 'react-redux'

const Analytics = () => {

  const userId = useSelector(state => state.user?.id)

  useEffect(() => {
    LogRocket.init('vpkznr/engage-pn0qj')
  }, [])

  useEffect(() => {
    if (userId) {
      LogRocket.identify(userId)
    }
  }, [userId])

  return null

}

export default Analytics