import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL

const apiRequest = (method, url, data, options) => {
  if (['get', 'put', 'post', 'patch', 'delete'].indexOf(method) === -1)
    throw new Error('Unsupported API method')
  // if (options) throw new Error('Options not yet supported')
  return axios({
    method,
    url,
    data: method !== 'get' ? data : undefined,
    params: method === 'get' ? data : undefined,
    ...options,
  })
    .then((response) => {
      return Promise.resolve(response.data)
    })
    .catch((error) => {
      // console.log('YIKES!', { method, url, data, options })
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.error(error.response)
        if (error.response.data) return Promise.reject(error.response.data)
        // return Promise.reject(error.response.data)
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.error(error.request)
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error(error)
      }
      return Promise.reject(error)
      // console.log(error)
      // alert(JSON.stringify(error))
    })
}

export const createAPI = (apiURL) => {

  const apiGet = (url, options) => apiRequest('get', apiURL + url, null, options)
  const apiPut = (url, body, options) => apiRequest('put', apiURL + url, body, options)
  const apiPost = (url, body, options) => apiRequest('post', apiURL + url, body, options)
  const apiPatch = (url, body, options) => apiRequest('patch', apiURL + url, body, options)
  const apiDelete = (url, options) => apiRequest('delete', apiURL + url, null, options)
  
  const api = {
    get: apiGet,
    put: apiPut,
    post: apiPost,
    patch: apiPatch,
    delete: apiDelete,
  }

  return api
  
}

const api = createAPI(API_URL)

export default api
