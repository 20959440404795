import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Table, Button, Modal } from 'react-bootstrap'
import { updateEvent } from 'store/event'
import EventForm from 'components/forms/event'
import CopyButton from 'components/copy-button'
import { imgBaseUrl } from 'lib/cloudinary'
import useAPI from 'hooks/use-api'

const SettingsSection = ({ event, liveMode, readOnly }) => {

    const eventId = event?.id
    const [modal, setModal] = useState()
    const [error, setError] = useState()
    const dispatch = useDispatch()
    const api = useAPI('/admin')

    const handleEditModal = () => setModal('edit')
    const handleHideModal = () => setModal()

    const handleEditEvent = values => {
        api.put('/event/' + eventId, values)
        .then(() => {
            dispatch(updateEvent({
                ...event,
                ...values
            }))
            setModal()
        })
        .catch(setError)
    }

    const start = new Date(event.startsAt)
    const end = new Date(event.endsAt)

    return (
        <>
            <h4>
                Settings
                {!liveMode && !readOnly && <Button size='md' variant='link' className='py-0 px-2' onClick={handleEditModal}>Edit</Button>}
            </h4>
            <Table size='sm'>
                <tbody>
                    <tr>
                        <th>Code</th>
                        <td>
                            {event.code}
                            <CopyButton className='p-0 ms-2 align-top' text={event.code} />
                        </td>
                    </tr>
                    <tr>
                        <th>Link</th>
                        <td>
                            <a href={'https://engage.imaginate.events?e=' + event.code} target='_blank' rel='noopener noreferer'>{'https://engage.imaginate.events?e=' + event.code}</a>
                        </td>
                    </tr>
                    <tr>
                        <th>Start</th>
                        <td>{start.toLocaleDateString() + ' ' + start.toLocaleTimeString()}</td>
                    </tr>
                    <tr>
                        <th>End</th>
                        <td>{end.toLocaleDateString() + ' ' + end.toLocaleTimeString()}</td>
                    </tr>
                    {/* <tr>
                        <th>Description</th>
                        <td>{event.description}</td>
                    </tr> */}
                    <tr>
                        <th>Hosted by</th>
                        <td>{event.hostedBy}</td>
                    </tr>
                    <tr>
                        <th>Website</th>
                        <td>{event.websiteUrl}</td>
                    </tr>
                    <tr>
                        <th>Logo</th>
                        <td>
                            {event.logoUrl && <img src={imgBaseUrl + '/t_scale_150/' + event.logoUrl} alt='' style={{ maxWidth: '9rem', maxHeight: '3rem' }} />}
                        </td>
                    </tr>
                    <tr>
                        <th>Primary color</th>
                        <td>
                            <div style={{ display: 'inline-block', verticalAlign: 'top', height: '1rem', width: '1rem', background: event.primaryColor, marginTop: '0.25rem', marginRight: '0.5rem' }} />
                            {event.primaryColor}
                        </td>
                    </tr>
                    <tr>
                        <th>Secondary color</th>
                        <td>
                            <div style={{ display: 'inline-block', verticalAlign: 'top', height: '1rem', width: '1rem', background: event.secondaryColor, marginTop: '0.25rem', marginRight: '0.5rem' }} />
                            {event.secondaryColor}
                        </td>
                    </tr>
                    <tr>
                        <th>Signup fields</th>
                        <td>{event.fields?.length > 0 ? event.fields.map((field, index) => field.label).join(', ') : ''}</td>
                    </tr>
                    <tr>
                        <th>Live password</th>
                        <td>
                            {event.password}
                            <CopyButton className='p-0 ms-2 align-top' text={event.password} />
                        </td>
                    </tr>
                </tbody>
            </Table>
            <Modal centered show={modal === 'edit'} onHide={handleHideModal} size='lg'>
                <Modal.Header>
                    Edit Event
                </Modal.Header>
                <Modal.Body>
                    <EventForm values={event} onSubmit={handleEditEvent} onReset={handleHideModal} error={error} />
                </Modal.Body>
            </Modal>
        </>
    )

}

export default SettingsSection