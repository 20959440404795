import { useCallback } from 'react'
import { Card, ListGroup } from 'react-bootstrap'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import SegmentListItem from './list-items/segment'
import SpeakerListItem from './list-items/speaker'
import SponsorListItem from './list-items/sponsor'
import StartupListItem from './list-items/startup'

const EntityList = ({ feature, entities, categories, liveMode, readOnly, onReorder, onEdit }) => {

    let categorized = 0
    const categoryIds = categories?.length > 0 ? categories.map(c => c.id) : []

    const featureType = feature?.type
    const EntityListItem = featureType === 'segment' ? SegmentListItem :
                            featureType === 'speaker' ? SpeakerListItem :
                            featureType === 'sponsor' ? SponsorListItem :
                            featureType === 'startup' ? StartupListItem :
                            null

    const handleDragEnd = useCallback((result, provided) => {
        if (result.reason !== 'DROP') return false
        // console.log(result)
        const itemId = result.draggableId
        const srcId = result.source.droppableId
        const srcIndex = result.source.index
        const dstId = result.destination.droppableId
        const dstIndex = result.destination.index
        // const relCats = categories?.filter(c => [srcId, dstId].indexOf(c.id) > -1)
        const newList = []
        for (let i = 0; i < (categories?.length || 0) + 1; i++) {
            const cId = categories[i]?.id || 'uncategorized'
            const eList = entities.filter(e => e.categoryId === cId || (!e.categoryId && cId === 'uncategorized'))
            for (let j = 0; j < eList.length; j++) {
                if ([srcId, dstId].indexOf(cId) === -1) {
                    newList.push(eList[j])
                } else {
                    const entity = {
                        ...eList[j],
                        sort: j
                    }
                    if (entity.id === itemId) {
                        entity.sort = dstIndex
                        if (entity.categoryId !== dstId) {
                            if (dstId === 'uncategorized') {
                                entity.categoryId = null
                            } else {
                                entity.categoryId = dstId
                            }
                        }
                    } else {
                        if ((entity.categoryId === srcId || (!entity.categoryId && srcId === 'uncategorized')) && entity.sort > srcIndex) entity.sort--
                        if ((entity.categoryId === dstId || (!entity.categoryId && dstId === 'uncategorized')) && entity.sort >= dstIndex) entity.sort++
                    }
                    newList.push(entity)
                }
            }
        }
        // for (let i = 0; i < entities?.length; i++) {
        //     const entity = entities[i]
        //     const output = {
        //         ...entity
        //     }
        //     if (entity.id === itemId) {
        //         output.categoryId = dstId === 'uncategorized' ? null : dstId
        //         output.sort = dstIndex
        //     } else if (srcId === dstId && (entity.categoryId === srcId || (!entity.categoryId && srcId === 'uncategorized'))) {
        //         console.log(srcIndex, dstIndex, entity.sort)
        //         if (entity.sort > srcIndex) output.sort--
        //         if (entity.sort >= dstIndex) output.sort++
        //     } else if (
        //         entity.categoryId === srcId ||
        //         (!entity.categoryId && srcId === 'uncategorized')
        //     ) {
        //         if (entity.sort > srcIndex) output.sort--
        //     } else if (
        //         entity.categoryId === dstId ||
        //         (!entity.categoryId && dstId === 'uncategorized')
        //     ) {
        //         if (entity.sort >= dstIndex) output.sort++
        //     }
        //     newList.push(output)
        // }
        onReorder(newList)
    }, [categories, entities, onReorder])

    return (
        <DragDropContext onDragEnd={handleDragEnd}>
            {categories?.length > 0 ? categories.map(category => {
                const filtered = entities?.filter(e => e.categoryId === category.id)
                categorized += filtered?.length || 0
                return (
                    <Card key={category.id} className='mb-2'>
                        <Card.Header className='bg-secondary small text-white fw-bold px-2 py-1'>
                            {category.name}
                        </Card.Header>
                        {liveMode || readOnly ? (
                            <ListGroup variant='flush' style={{ minHeight: '2.5rem' }}>
                                {filtered?.map(entity => {
                                    categorized++
                                    return (
                                        <EntityListItem key={entity.id} entity={entity} liveMode={liveMode} readOnly={readOnly} />
                                    )
                                })}
                            </ListGroup>
                        ) : ( // list={entities || []} setList={onReorder}>
                            <Droppable droppableId={category.id}>
                                {(provided, snapshot) => (
                                    <ListGroup ref={provided.innerRef} className='list-group-flush' style={{ minHeight: '2.5rem' }}>
                                        {filtered?.map((entity, index) => {
                                            // categorized++
                                            return (
                                                <Draggable index={index} key={entity.id} draggableId={entity.id}>
                                                    {(provided, snapshot) => (
                                                        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className='list-group-item p-0'>
                                                            <EntityListItem
                                                                entity={entity}
                                                                liveMode={liveMode}
                                                                readOnly={readOnly}
                                                                onClick={() => onEdit(entity)}
                                                            />
                                                        </div>
                                                    )}
                                                </Draggable>
                                            )
                                        })}
                                        {provided.placeholder}
                                    </ListGroup>
                                )}
                            </Droppable>
                        )}
                    </Card>
                )
            }) : (
                <Card className='mb-2'>
                    {liveMode || readOnly ? (
                        <ListGroup variant='flush'>
                            {entities?.map(entity => {
                                return (
                                    <div key={entity.id} className='list-group-item p-0'>
                                        <EntityListItem entity={entity} liveMode={liveMode} readOnly={readOnly} />
                                    </div>
                                )
                            })}
                        </ListGroup>
                    ) : (
                        // <ReactSortable tag={ListGroup} className='list-group-flush' list={entities || []} setList={onReorder}>
                        // </ReactSortable>
                        <Droppable droppableId='uncategorized'>
                            {(provided, snapshot) => (
                                <ListGroup ref={provided.innerRef} className='list-group-flush' style={{ minHeight: '2.5rem' }}>
                                    {entities?.map((entity, index) => {
                                        return (
                                            <Draggable index={index} key={entity.id} draggableId={entity.id}>
                                                {(provided, snapshot) => (
                                                    <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className='list-group-item p-0'>
                                                        <EntityListItem entity={entity} liveMode={liveMode} readOnly={readOnly} onClick={() => onEdit(entity)} />
                                                    </div>
                                                )}
                                            </Draggable>
                                        )
                                    })}
                                    {provided.placeholder}
                                </ListGroup>
                            )}
                        </Droppable>
                    )}
                </Card>
            )}
            {categories?.length > 0 && entities.length > categorized && (
                <Card className='mb-2'>
                    <Card.Header className='bg-danger small text-white fw-bold px-2 py-1'>
                        Uncategorized
                    </Card.Header>
                    <Droppable droppableId='uncategorized'>
                        {(provided, snapshot) => (
                            <ListGroup ref={provided.innerRef} className='list-group-flush' style={{ minHeight: '2.5rem' }}>
                                {entities?.filter(e => !e.categoryId || categoryIds.indexOf(e.categoryId) === -1).map((entity, index) => (
                                    <Draggable index={index} key={entity.id} draggableId={entity.id}>
                                        {(provided, snapshot) => (
                                            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className='list-group-item p-0'>
                                                <EntityListItem
                                                    entity={entity}
                                                    liveMode={liveMode}
                                                    readOnly={readOnly}
                                                    onClick={() => onEdit(entity)}
                                                />
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </ListGroup>
                        )}
                    </Droppable>
                </Card>
            )}
        </DragDropContext>
    )

}

// <ListGroup variant='flush'>
//     {entities?.filter(e => !e.categoryId || categoryIds.indexOf(e.categoryId) === -1).map(entity => (
//         <EntityListItem key={entity.id} entity={entity} liveMode={liveMode} readOnly={readOnly} onClick={!liveMode && !readOnly ? () => onEdit(entity) : null} />
//     ))}
// </ListGroup>

export default EntityList