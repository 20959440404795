// import { Container, Row, Col } from 'react-bootstrap'
// import { useTranslation } from 'react-i18next'
// import engageLogo from 'assets/engage-logo.png'
import Header from 'components/layout/header'

const AuthHeader = () => {
  // const { t } = useTranslation()
  return (
    <Header loggedIn={false} />
  )
  // return (
  //   <Container className='p-3 mb-3'>
  //     <Row>
  //       <Col>
  //       </Col>
  //       <Col className='text-end'>
  //         Imaginate
  //       </Col>
  //     </Row>
  //   </Container>
  // )
}

export default AuthHeader
