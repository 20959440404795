import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Button, ListGroup } from 'react-bootstrap'
import useActions from 'hooks/use-actions'

const ReportsScreen = () => {

    const event = useSelector(state => state.event)
    const featureTypes = []
    event?.features?.forEach(feature => {
        if (featureTypes.indexOf(feature.type) === -1) featureTypes.push(feature.type)
    })
    const hasStartup = featureTypes.indexOf('startup') > -1

    return (
        <ListGroup variant='flush' className='shadow rounded'>
            {hasStartup && <StartupRatingsReport />}
            {hasStartup && <StartupParticipantsReport />}
            {/* <StartupConnectionsReport /> */}
        </ListGroup>
    )

}

export default ReportsScreen

const generateCSV = (cols, rows, fileName) => { // col: { key: first-name, label: First Name } // row: { first-name: Steve }
    if (!cols || !rows) throw new Error('Missing rows and/or cols for CSV')
    let data = ''
    const keys = []
    cols.forEach((col, index) => {
        keys.push(col.key)
        const label = (typeof col.label === 'string') ? col.label.replace(/"/g, '\'') : (typeof col.label === 'number') ? col.label : ''
        data += '"' + label + '"'
        if (index === cols.length - 1) {
            data += '\r\n'
        } else {
            data += ','
        }
    })
    rows.forEach(row => {
        keys.forEach((key, index) => {
            const value = (typeof row[key] === 'string') ? row[key].replace(/"/g, '\'') : (typeof row[key] === 'number') ? row[key] : ''
            data += '"' + value + '"'
            if (index === keys.length - 1) {
                data += '\r\n'
            } else {
                data += ','
            }
        })
    })
    if (!fileName) return data
    const blob = new Blob([data], { type: 'text/csv' })
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.setAttribute('href', url)
    a.setAttribute('download', fileName)
    a.click()
}


const StartupParticipantsReport = () => {

    const event = useSelector(state => state.event)
    const guests = event?.guests
    const feature = event?.features?.find(f => f.type === 'startup')
    const featureId = feature?.id
    const startups = event?.entities?.filter(e => e.featureId === featureId)
    const actions = useActions(featureId, 'vote')

    const results = useMemo(() => {
        if (!actions || !guests || !startups) return null
        const ratings = []
        actions.forEach(action => {
            if (action.featureId !== featureId || action.type !== 'vote') return false
            const existing = ratings.findIndex(r => r.guestId === action.guestId && r.entityId === action.entityId)
            if (existing > -1) return false
            ratings.push(action)
        })
        return ratings.map(action => {
            const guest = guests.find(guest => guest.id === action.guestId)
            const startup = startups.find(startup => startup.id === action.entityId)
            return {
                name: guest.name,
                email: guest.email,
                company: guest.field1.replace(', LLC', ''),
                startup: startup.name,
                rating: action.data.rating && Math.round(action.data.rating),
                comments: action.data.comments,
                createdAt: action.createdAt
            }
        }).sort((a, b) => a.startup > b.startup ? 1 : -1)
    }, [actions, featureId, guests, startups])

    const handleDownload = () => {
        generateCSV([
            { key: 'startup', label: 'Startup' },
            { key: 'name', label: 'Name' },
            { key: 'company', label: 'Company' },
            { key: 'email', label: 'Email' },
            { key: 'rating', label: 'Rating' },
            { key: 'comments', label: 'Comments' },
        ], results, event.name + ' - Voting Details.csv')
    }

    return results && (
        <>
            <div className='list-group-item d-flex justify-content-between align-items-center'>
                <h4 className='mb-0'>Voting Details</h4>
                {actions?.length > 0 ? <Button size='sm' className='me-n2' onClick={handleDownload}>Download</Button> : <div className='py-1 text-muted'>No results</div>}
            </div>
            {/* <div style={{ userSelect: 'all' }}>
                <div className='small'>
                    "Startup", "Name", "Role", "Rating", "Comments"
                </div>
                {results.map(result => (
                    <div key={result.id} className='small'>
                        "{result.startup}", "{result.name}", "{result.company}", {result.rating}, "{result.comments}"
                    </div>
                ))}
            </div> */}
        </>
    )

}

const StartupRatingsReport = () => {
    const event = useSelector(state => state.event)
    const feature = event?.features?.find(f => f.type === 'startup')
    const featureId = feature?.id
    const startups = event?.entities?.filter(e => e.featureId === featureId)
    const actions = useActions(featureId)

    const results = useMemo(() => {
        if (!startups || !actions) return []
        return startups.map(startup => {
            const id = startup.id
            const uniqueVotes = []
            let resultCount = 0
            let ratingScore = 0
            actions.sort((a, b) => new Date(a.createdAt) > new Date(b.createdAt) ? 1 : -1).filter(action => {
                if (action.featureId !== featureId || action.type !== 'vote' || action.entityId !== id || !action.data) return false
                if (uniqueVotes.indexOf(action.guestId) > -1) return false
                uniqueVotes.push(action.guestId)
                return true
            }).forEach(action => {
                resultCount++
                ratingScore += Number(action.data.rating)
            })
            return {
                ...startup,
                rating: resultCount > 0 ? Math.round((ratingScore / resultCount) * 10) / 10 : 0,
                votes: uniqueVotes.length
            }
        })
    }, [startups, actions, featureId])

    const handleDownload = () => {
        generateCSV([
            { key: 'name', label: 'Startup' },
            { key: 'rating', label: 'Rating' },
            { key: 'votes', label: 'Votes' },
        ], results, event.name + ' - Voting Summary.csv')
    }

    return (
        <>
            <div className='list-group-item d-flex justify-content-between align-items-center'>
                <h4 className='mb-0'>Voting Summary</h4>
                {actions?.length > 0 ? <Button size='sm' className='me-n2' onClick={handleDownload}>Download</Button> : <div className='py-1 text-muted'>No results</div>}
            </div>
            {/* <h3>Startup Totals</h3>
            <div style={{ userSelect: 'all' }}>
                <div className='small'>
                    "Startup", "Rating", "Votes"
                </div>
                {results.map(startup => (
                    <div key={startup.id} className='small'>
                        "{startup.name}", {startup.rating}, {startup.votes}
                    </div>
                ))}
            </div> */}
        </>
    )

}

// const StartupConnectionsReport = () => {

//     const event = useSelector(state => state.event)
//     const guests = event?.guests
//     const feature = event?.features?.find(f => f.type === 'startup')
//     const featureId = feature?.id
//     const startups = event?.entities?.filter(e => e.featureId === featureId)
//     const actions = useActions(featureId)

//     const results = useMemo(() => {
//         if (!actions || !guests || !startups) return null
//         const connections = []
//         actions.forEach(action => {
//             if (action.featureId !== featureId || (action.type !== 'connect' && action.type !== 'disconnect')) return false
//             const existing = connections.findIndex(c => c.guestId === action.guestId)
//             if (action.type === 'connect') {
//                 if (existing > -1) return false
//                 connections.push(action)
//             } else { // action.type === 'disconnect'
//                 if (existing === -1) return false
//                 connections.splice(existing, 1)
//             }
//         })
//         return connections.map(action => {
//             const guest = guests.find(guest => guest.id === action.guestId)
//             const startup = startups.find(startup => startup.id === action.entity)
//             return {
//                 name: guest.name,
//                 email: guest.email,
//                 company: guest.field1.replace(', LLC', ''),
//                 startup: startup.name,
//                 quality: action.data?.quality,
//                 relevance: action.data?.relevance,
//                 createdAt: action.createdAt
//             }
//         }).sort((a, b) => a.startup > b.startup ? 1 : -1)
//     }, [actions])
    
//     return results && (
//         <>
//             <h3>Connections</h3>
//             <div style={{ userSelect: 'all' }}>
//                 <div className='small'>
//                     "Startup", "Name", "Company", "Email"
//                 </div>
//                 {results.map(result => (
//                     <div key={result.id} className='small'>
//                         "{result.startup}", "{result.name}", "{result.company}", "{result.email}"
//                     </div>
//                 ))}
//             </div>
//         </>
//     )

// }