import { useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { FormControl, FormSelect, FormLabel, InputGroup } from 'react-bootstrap'
import { useField } from 'formik'
import { Eye, EyeOff } from 'react-feather'

const FormikField = ({ className, style, inputClassName, inputStyle, size, ...props }) => {
  const [field, meta] = useField(props) // , helpers
  return (
    <div
      style={style}
      className={classnames(
        props.type === 'checkbox' && 'form-check',
        props.type === 'switch' && 'form-switch',
        props.type === 'radio' && 'form-radio',
        props.type === 'checkbox' && size === 'lg' && 'form-check-lg',
        props.type === 'switch' && size === 'lg' && 'form-switch-lg',
        props.type === 'radio' && size === 'lg' && 'form-radio-lg',
        'mb-3',
        className
      )}
    >
      {props.label && props.type !== 'checkbox' && (
        <FormLabel htmlFor={props.name + 'Field'}>{props.label}</FormLabel>
      )}
      <div className={classnames(
        (props.suffix || props.prefix) && 'input-group',
        !!meta.error && 'is-invalid'
      )}>
        {props.prefix && (
          typeof props.prefix === 'string' ? <InputGroup.Text>{props.prefix}</InputGroup.Text> : props.prefix
        )}
        {props.type === 'select' ? ( // || props.as === 'select'
          <FormSelect
            {...field}
            {...props}
            size={size}
            className={inputClassName}
            style={inputStyle}
            invalid={meta.error ? 'true' : undefined}
            id={props.name + 'Field'}
          >
            {props.children}
          </FormSelect>
        ) : props.type === 'password' ? (
          <PasswordFormControl
            {...field}
            {...props}
            size={size}
            className={inputClassName}
            style={inputStyle}
            invalid={meta.error ? 'true' : undefined}
            id={props.name + 'Field'}
          />
        ) : (
          // ) : props.type === 'textarea' ? (
          // <FormControl {...field} {...props} isInvalid={!!meta.error} />
          // ) : props.type === 'checkbox' ? (
          //     <FormControl type='checkbox' {...field} {...props} isInvalid={!!meta.error} id={props.name + 'Checkbox'} />
          <FormControl
            {...field}
            {...props}
            size={size}
            className={inputClassName}
            style={inputStyle}
            invalid={meta.error ? 'true' : undefined}
            id={props.name + 'Field'}
          />
        )}
          {props.suffix && (
            typeof props.suffix === 'string' ? <InputGroup.Text>{props.suffix}</InputGroup.Text> : props.suffix
          )}
      </div>
      {!!props.label && props.type === 'checkbox' && (
        <FormLabel htmlFor={props.name + 'Field'}>{props.label}</FormLabel>
      )}
      {!!meta.touched && !!meta.error && (
        <FormControl.Feedback type='invalid'>{meta.error}</FormControl.Feedback>
      )}
    </div>
  )
}

export default FormikField

FormikField.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  size: PropTypes.string,
  prefix: PropTypes.any,
  suffix: PropTypes.any,
  className: PropTypes.string,
  style: PropTypes.object,
  inputClassName: PropTypes.string,
  inputStyle: PropTypes.object,
  children: PropTypes.node
}

const PasswordFormControl = (props) => {
  const [focus, setFocus] = useState(false)
  const [visible, setVisible] = useState(false)
  const toggleVisible = e => {
    e.preventDefault()
    e.stopPropagation()
    setVisible(visible => !visible)
  }
  const handleFocus = () => setFocus(true)
  const handleBlur = () => setFocus(false)
  return (
    <div className={classnames(
      'form-control',
      props.invalid && 'is-invalid',
      'p-0',
      'd-flex',
      'overflow-hidden',
      props.size === 'lg' && 'form-control-lg',
      props.size === 'sm' && 'form-control-sm',
      focus && 'focus'
    )}>
      <input
        {...props}
        style={{ fontSize: 'inherit' }}
        id={props.name + 'Field'}
        type={visible ? 'text' : 'password'}
        className='flex-grow-1 border-0 form-control'
        // style={{ outline: 'none', padding: '0.375rem 0.75rem', lineHeight: '1.5' }}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
      <button type='button' onClick={toggleVisible} className='bg-transparent border-0 lh-1 text-secondary' style={{ padding: '0 0.375rem' }}>
        {visible ? <EyeOff style={{ height: '1.25rem' }} /> : <Eye style={{ height: '1.25rem' }} />}
      </button>
    </div>
  )
}

PasswordFormControl.propTypes = {
  name: PropTypes.string,
  invalid: PropTypes.bool,
  size: PropTypes.string
}