export const LOAD_USER = 'engage/user/LOAD'
export const UPDATE_USER = 'engage/user/UPDATE'
export const CLEAR_USER = 'engage/user/CLEAR'

const initialState = () => Object.create(null)

const reducer = (state = initialState(), { type, data }) => {
    switch (type) {
        case LOAD_USER:
            return { ...data }
        case UPDATE_USER:
            return { ...state, ...data }
        case CLEAR_USER:
            return initialState()
        default:
            return state
    }
}

export default reducer

export const loadUser = userData => {
    return {
        type: LOAD_USER,
        data: userData
    }
}

export const updateUser = userData => {
    return {
        type: UPDATE_USER,
        data: userData
    }
}

export const clearUser = () => {
    return {
        type: CLEAR_USER
    }
}